import React, { useState } from "react";
import {
  InputLabel,
  makeStyles,
  Typography,
  useMediaQuery,
  Select,
  MenuItem,
  FormControl,
} from "@material-ui/core";
import Avatar from "../../Avatar";
import LetterAvatar from "../../LetterAvatar/index";
import { isFreeTialTypes } from "../../../utils/index";

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },

  avatarContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "fit-content",
  },
  avatarContainerCompany: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "fit-content",
  },
  avatar: {
    display: "flex",
    justifyContent: "flex-start",
    height: "fit-content",
    width: "fit-content",
  },
  textColumn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "fit-content",
  },
  textColumnEmployee: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    marginRight: "20%",
    textAlign: "center",
  },

  textColumnUser: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "fit-content",
    marginLeft: 5,
  },

  leftContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "43%",
    marginTop: 8,
  },
  leftContainerCompany: {
    display: "flex",
    justifyContent: "space-between",
    width: "fit-content",
    marginTop: 8,
  },
  centerContainerEmployee: {
    display: "flex",
    justifyContent: "space-between",
    direction: "rtl",
    width: "20%",
  },
  centerContainerCompany: {
    display: "flex",
    justifyContent: "space-between",
    width: "40%",
  },
  centerContainerAdvisor: {
    display: "flex",
    justifyContent: "space-evenly",
    width: "30%",
  },
  rightContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "22%",
  },
  rightContainerEmployee: {
    display: "flex",
    justifyContent: "space-between",
    width: "25%",
  },

  dateStatusGroup: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },

  nameHeader: {
    fontSize: "1rem",
    fontWeight: 600,
  },
  value: {
    fontSize: "0.75rem",
    color: theme.palette.secondary.dark,
  },
  valueActive: {
    fontSize: "0.75rem",
    color: "rgb(13, 173, 0)",
  },
  valueInactive: {
    fontSize: "0.75rem",
    color: theme.palette.secondary.error,
  },
  valuePaused: {
    fontSize: "0.75rem",
    color: "gold",
  },
  valueNew: {
    fontSize: "0.75rem",
    color: theme.palette.primary.main,
  },
  valueDark: {
    fontWeight: 400,
  },
  separator: {
    marginTop: 7,
  },
  buttonContainer: {
    outline: "none",
    border: "none",
    cursor: "pointer",
    backgroundColor: "transparent",
    "&:disabled": {
      cursor: "default",
    },
  },
}));

function AnswerCardHeader(props) {
  const {
    status,
    firstName,
    lastName,
    userTitle,
    companyName,
    phoneNumber,
    email,
    accountLevel,
    lastLoginDate,
    registrationDate,
    headerFormat,
    avatarImage,
    boardOwnerName,
    boardType,
    seatsCount,
    boardTotalSeats,
    planCode,
    advisorNumberOfBoards,
    isFreeTrial,
    editUserInfoDisabled,
  } = props;
  const [userStatus, setUserStatus] = useState(status);
  const isSM = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles(isSM);
  const [showEditUserInfoMdoal, setShowEditUserInfoModal] = useState(false);

  function statusStyleSelector() {
    if (status === "Active") {
      return classes.valueActive;
    }
    if (status === "New") {
      return classes.valueNew;
    }
    if (status === "Inactive") {
      return classes.valueInactive;
    }
    if (status === "Paused") {
      return classes.valuePaused;
    }
    return classes.valueActive;
  }

  function handleStatusChange(event) {
    statusStyleSelector();
    setUserStatus(event.target.value);
  }

  function reformatDate(date) {
    const setDate = new Date(date);
    const reformatedDate =
      setDate.getDate() +
      "/" +
      (setDate.getMonth() + 1) +
      "/" +
      setDate.getFullYear();
    return reformatedDate;
  }

  function leftContainerFormatSelector() {
    if (headerFormat === "company") {
      return (
        <div className={classes.leftContainerCompany}>
          <div className={classes.avatarContainerCompany}>
            <div className={classes.avatar}>
              <Avatar image={avatarImage} size="adminUserCard" />
            </div>
            <div className={classes.textColumnUser}>
              <div className={classes.textHeaderCompany}>
                <Typography className={classes.nameHeader}>
                  {companyName}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className={classes.leftContainer}>
        <div className={classes.avatarContainer}>
          <div className={classes.avatar}>
            {headerFormat === "employee" ? (
              <LetterAvatar
                firstName={firstName}
                lastName={lastName}
                size="large"
              />
            ) : (
              <Avatar image={avatarImage} size="adminUserCard" />
            )}
          </div>
          <div className={classes.textColumnUser}>
            <div className={classes.textHeader}>
              <Typography className={classes.nameHeader} color="textPrimary">
                {firstName}
                {lastName}
              </Typography>
            </div>
            <div className={classes.textValue}>
              <Typography className={classes.value}>{userTitle}</Typography>
            </div>
            <div className={classes.textValue}>
              <Typography className={classes.value}>{companyName}</Typography>
            </div>
          </div>
        </div>
        <div className={classes.textColumn} style={{ marginLeft: "10%" }}>
          <div className={classes.textValue}>
            <Typography
              variant="caption"
              className={classes.valueDark}
              color="textPrimary"
            >
              {phoneNumber}
            </Typography>
          </div>
          <div className={classes.textValue}>
            <Typography
              variant="caption"
              className={classes.valueDark}
              color="textPrimary"
            >
              {email}
            </Typography>
          </div>
        </div>
      </div>
    );
  }

  function centerFormatSelector() {
    if (headerFormat === "employee") {
      return (
        <div className={classes.centerContainerEmployee}>
          <div className={classes.textColumnEmployee}>
            <Typography variant="caption" className={classes.valueDark}>
              Account Level
            </Typography>
            <div className={classes.separator}>
              <Typography variant="caption">{accountLevel}</Typography>
            </div>
          </div>
        </div>
      );
    }
    if (headerFormat === "company") {
      return (
        <div className={classes.centerContainerCompany}>
          <div className={classes.textColumn}>
            <Typography variant="caption" className={classes.valueDark}>
              Board Owner
            </Typography>
            <div className={classes.separator}>
              <Typography variant="caption" className={classes.valueDark}>
                {boardOwnerName}
              </Typography>
            </div>
          </div>
          <div className={classes.textColumn}>
            <Typography variant="caption" className={classes.valueDark}>
              Board
            </Typography>
            <div className={classes.separator}>
              <Typography variant="caption">{boardType}</Typography>
            </div>
          </div>
          <div className={classes.textColumn}>
            <Typography variant="caption" className={classes.valueDark}>
              Seats
            </Typography>
            <div className={classes.separator}>
              <div>
                <Typography variant="caption" className={classes.valueDark}>
                  {seatsCount}
                </Typography>
                <Typography variant="caption"> of {boardTotalSeats}</Typography>
              </div>
            </div>
          </div>
        </div>
      );
    }
    if (headerFormat === "advisor") {
      return (
        <div className={classes.centerContainerAdvisor}>
          <div className={classes.textColumn}>
            <Typography variant="caption" className={classes.value}>
              Number of Boards
            </Typography>
            <div className={classes.separator}>
              <Typography variant="caption" className={classes.value}>
                {advisorNumberOfBoards} of 3
              </Typography>
            </div>
          </div>
          <div className={classes.textColumn}>
            <Typography variant="caption" className={classes.valueDark}>
              Free Trial
            </Typography>
            <div className={classes.separator}>
              <React.Fragment>
                {isFreeTrial ? (
                  <Typography
                    variant="caption"
                    className={
                      isFreeTrial === isFreeTialTypes.YES
                        ? classes.valueActive
                        : classes.valueDark
                    }
                  >
                    {isFreeTrial === isFreeTialTypes.YES
                      ? isFreeTialTypes.YES
                      : isFreeTialTypes.NO}
                  </Typography>
                ) : null}
              </React.Fragment>
            </div>
          </div>
        </div>
      );
    }
  }

  return (
    <div className={classes.headerContainer}>
      <button
        type="button"
        className={classes.buttonContainer}
        onClick={() => setShowModal(true)}
        disabled={editUserInfoDisabled}
      >
        {leftContainerFormatSelector()}
      </button>

      <React.Fragment>{centerFormatSelector()}</React.Fragment>

      <div
        className={
          headerFormat === "employee"
            ? classes.rightContainerEmployee
            : classes.rightContainer
        }
      >
        <React.Fragment>
          {headerFormat === "employee" ? (
            <div className={classes.textColumn}>
              <Typography className={classes.value}>Last Login Date</Typography>
              <div className={classes.separator}>
                <Typography className={classes.value}>
                  {reformatDate(lastLoginDate)}
                </Typography>
              </div>
            </div>
          ) : (
            <div className={classes.textColumn}>
              <Typography variant="caption" className={classes.valueDark}>
                Plancode
              </Typography>
              <div className={classes.separator}>
                <Typography variant="caption" className={classes.valueDark}>
                  {planCode}
                </Typography>
              </div>
            </div>
          )}

          <div className={classes.textColumn}>
            <Typography className={classes.value}>Registration</Typography>
            <div className={classes.separator}>
              <Typography className={classes.value}>
                {reformatDate(registrationDate)}
              </Typography>
            </div>
          </div>
          <div className={classes.textColumn}>
            <Typography variant="caption" className={classes.valueDark}>
              Status
            </Typography>
            <div className={classes.separator}>
              <FormControl className={classes.formControl}>
                <InputLabel id="status-label">{userStatus}</InputLabel>
                <Select
                  className={reformatDate()}
                  labelId="status-label"
                  id="status"
                  value={userStatus}
                  onChange={handleStatusChange}
                >
                  <MenuItem value="active">active</MenuItem>
                  <MenuItem value="inactive">inactive</MenuItem>
                  <MenuItem value="new">new</MenuItem>
                  <MenuItem value="paused">paused</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
        </React.Fragment>
      </div>
    </div>
  );
}

export default AnswerCardHeader;
