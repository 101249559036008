import React from "react";
import { makeStyles, Typography, useMediaQuery } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: (isSM) => (isSM ? 80 : 100),
    height: 30,
    margin: 0,
    backgroundColor: theme.palette.primary.main,
    borderRadius: "8%",
  },
  label: {
    color: "white",
    textAlign: "center",
    width: "100%",
    position: "absolute",
    fontWeight: "bold",
    top: "15%",
    paddingRight: "11px",
  },
  icon: {
    color: "white",
    width: (isSM) => (isSM ? 20 : 25),
    height: (isSM) => (isSM ? 20 : 25),
    margin: (isSM) => (isSM ? "2% 8% auto auto" : "0% 8% auto auto"),
    paddingBottom: (isSM) => (isSM ? 0 : "1px"),
    bottom: "20%",
  },
  center: {
    margin: "auto",
  },
}));

function ActionButton(props) {
  const { itemList, label } = props;
  const isSM = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles(isSM);
  const handleChange = (event) => {
    event.target.value.callback();
  };

  return (
    <FormControl
      className={classes.formControl}
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      <Typography
        className={classes.label}
        variant={isSM ? "caption" : "body2"}
      >
        {label}
      </Typography>
      <Select
        className={classes.select}
        disableUnderline
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
        }}
        inputProps={{
          classes: {
            icon: classes.icon,
          },
        }}
        id="sort-select"
        value={label}
        onChange={handleChange}
      >
        {itemList.map((item) => {
          return (
            <MenuItem key={item.title} value={item}>
              {item.title}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

export default ActionButton;
