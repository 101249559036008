import React, { useState } from "react";
import {
  Menu,
  MenuItem,
  Fade,
  Backdrop,
  makeStyles,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { fade } from "@material-ui/core/styles/colorManipulator";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import EditIcon from "@material-ui/icons/Edit";
import ACIcon from "../../../ACIcon";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

const useStyles = makeStyles((theme) => ({
  backDrop: {
    zIndex: 999,
    backgroundColor: fade("#000000", 0.05),
  },
  elipsies: {
    color: theme.palette.secondary.main,
    position: "relative",
    fontSize: (isSM) => (isSM ? 20 : 25),
  },
  button: {
    padding: 0,
    margin: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "10px",
    outline: "none",
    backgroundColor: "transparent",
    cursor: "pointer",
    border: "none",
    width: 10,
    height: 22,
    overflow: "hidden",
  },
  iconStyle: {
    fontSize: 15,
    marginBottom: "2px",
    marginRight: "5px",
  },
  menuItem: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  menuItemText: {
    marginBottom: "-3px",
    fontWeight: 600,
  },
}));

function VerticalElipsiesMenu(props) {
  const { editClick /*, deleteClick */, pinClick, isPinned } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const isSM = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles(isSM);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const Default = () => {
    return (
      <React.Fragment>
        <MenuItem onClick={() => editClick()} className={classes.MenuItem}>
          <div className={classes.menuItem}>
            <EditIcon className={classes.iconStyle} />
            <Typography variant="caption" className={classes.menuItemText}>
              Edit
            </Typography>
          </div>
        </MenuItem>
        {pinClick ? (
          <MenuItem onClick={() => pinClick()} className={classes.MenuItem}>
            <div className={classes.menuItem}>
              <ACIcon
                classes={{
                  root: classes.iconStyle,
                }}
                iconname="push-pin"
              />
              <Typography variant="caption" className={classes.menuItemText}>
                {isPinned ? "Unpin" : "Pin"}
              </Typography>
            </div>
          </MenuItem>
        ) : null}
        {/* <MenuItem onClick={() => deleteClick()}>
          <div className={classes.menuItem}>
            <DeleteForeverIcon className={classes.iconStyle} />
            <Typography variant="caption" className={classes.menuItemText}>
              Delete
            </Typography>
          </div>
        </MenuItem> */}
      </React.Fragment>
    );
  };

  function renderContentMenu() {
    return <Default />;
  }

  return (
    <div>
      <button
        type="button"
        aria-controls="fade-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className={classes.button}
      >
        <MoreVertIcon className={classes.elipsies} />
      </button>
      <Backdrop className={classes.backDrop} open={open} onClick={handleClose}>
        <Menu
          id="fade-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <div>{renderContentMenu()}</div>
        </Menu>
      </Backdrop>
    </div>
  );
}

export default VerticalElipsiesMenu;
