import React from "react";
import { makeStyles, ThemeProvider } from "@material-ui/core";
import { default as ACTheme } from "../../themes";

const useStyles = makeStyles(() => ({
  projectCardContainer: {
    display: "flex",
    flexDirection: "column",
    width: (props) => (!props.width ? "105px" : `${props.width}px`),
    height: "69px",
  },
  image: {
    width: "100%",
    height: "100%",
    borderRadius: "3px",
  },
}));

export default function CarosuelCard(props) {
  return (
    <ThemeProvider theme={ACTheme}>
      <CarosuelCardContent {...props} />
    </ThemeProvider>
  );
}

function CarosuelCardContent(props) {
  const { img } = props;

  const classes = useStyles(props);

  return (
    <div className={classes.projectCardContainer} id="carousel-card">
      <img src={img} className={classes.image} alt="" />
    </div>
  );
}
