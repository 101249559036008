import React, { useState, useEffect } from "react";
import { makeStyles, Typography, ThemeProvider } from "@material-ui/core";
import {
  getTimeDifferenceInSeconds,
  getLastLoginTimeLabel,
} from "../../utils/time";
import { default as ACTheme } from "../../themes";

const useStyles = makeStyles((theme) => ({
  timeContainer: {
    display: "flex",
    width: "fit-content",
    height: "fit-content",
  },
  timeText: {
    color: theme.palette.secondary.dark,
  },
}));

export default function TimeElapsed(props) {
  return (
    <ThemeProvider theme={ACTheme}>
      <TimeElapsedContent {...props} />
    </ThemeProvider>
  );
}

function TimeElapsedContent(props) {
  const classes = useStyles();
  const { initialTime, dashboardFormat, size } = props;
  const [timeLabel, setTimeLabel] = useState();
  const [resultNumber, setResultNumber] = useState();
  const [resultUnit, setResultUnit] = useState();
  const [intervalID, setIntervalID] = useState();

  function transformNumber() {
    if (timeLabel) {
      const substractNumber = timeLabel.slice(0, -1);
      const substractLetter = timeLabel.slice(-1);

      setResultNumber(parseInt(substractNumber, 10));
      setResultUnit(substractLetter);
    }
  }

  useEffect(() => {
    if (initialTime) {
      let seconds = getTimeDifferenceInSeconds(initialTime);
      const timerId = setInterval(() => {
        setTimeLabel(getLastLoginTimeLabel(seconds));
        seconds += 1;
      }, 1000);
      setIntervalID(timerId);
    }
    return () => clearInterval(intervalID);
  }, [initialTime]);

  useEffect(() => {
    if (timeLabel) {
      transformNumber();
    }
  }, [timeLabel, dashboardFormat]);

  function formatLabelTransform() {
    // minutes
    if (dashboardFormat && resultNumber < 2 && resultUnit === "m") {
      return (
        <Typography
          variant={size === "lg" ? "h3" : "caption"}
          className={classes.timeText}
          color="secondary"
        >
          {`${resultNumber} minute ago`}
        </Typography>
      );
    }
    if (dashboardFormat && resultNumber > 1 && resultUnit === "m") {
      return (
        <Typography
          variant={size === "lg" ? "h3" : "caption"}
          className={classes.timeText}
          color="secondary"
        >
          {`${resultNumber} minutes ago`}
        </Typography>
      );
    }
    // hours
    if (dashboardFormat && resultNumber < 2 && resultUnit === "h") {
      return (
        <Typography
          variant={size === "lg" ? "h3" : "caption"}
          className={classes.timeText}
          color="secondary"
        >
          {`${resultNumber} hour ago`}
        </Typography>
      );
    }
    if (dashboardFormat && resultNumber > 1 && resultUnit === "h") {
      return (
        <Typography
          variant={size === "lg" ? "h3" : "caption"}
          className={classes.timeText}
          color="secondary"
        >
          {`${resultNumber} hours ago`}
        </Typography>
      );
    }
    // days
    if (dashboardFormat && resultNumber < 2 && resultUnit === "d") {
      return (
        <Typography
          variant={size === "lg" ? "h3" : "caption"}
          className={classes.timeText}
          color="secondary"
        >
          {`${resultNumber} day ago`}
        </Typography>
      );
    }
    if (dashboardFormat && resultNumber > 1 && resultUnit === "d") {
      return (
        <Typography
          variant={size === "lg" ? "h3" : "caption"}
          className={classes.timeText}
          color="secondary"
        >
          {`${resultNumber} days ago`}
        </Typography>
      );
    }
    // weeks
    if (dashboardFormat && resultNumber < 2 && resultUnit === "w") {
      return (
        <Typography
          variant={size === "lg" ? "h3" : "caption"}
          className={classes.timeText}
          color="secondary"
        >
          {`${resultNumber} week ago`}
        </Typography>
      );
    }
    if (dashboardFormat && resultNumber > 1 && resultUnit === "w") {
      return (
        <Typography
          variant={size === "lg" ? "h3" : "caption"}
          className={classes.timeText}
          color="secondary"
        >
          {`${resultNumber} weeks ago`}
        </Typography>
      );
    }
    // months
    if (dashboardFormat && resultNumber < 2 && resultUnit === "o") {
      return (
        <Typography
          variant={size === "lg" ? "h3" : "caption"}
          className={classes.timeText}
          color="secondary"
        >
          {`${resultNumber} month ago`}
        </Typography>
      );
    }
    if (dashboardFormat && resultNumber > 1 && resultUnit === "o") {
      return (
        <Typography
          variant={size === "lg" ? "h3" : "caption"}
          className={classes.timeText}
          color="secondary"
        >
          {`${resultNumber} months ago`}
        </Typography>
      );
    }
    // years
    if (dashboardFormat && resultNumber < 2 && resultUnit === "y") {
      return (
        <Typography
          variant={size === "lg" ? "h3" : "caption"}
          className={classes.timeText}
          color="secondary"
        >
          {`${resultNumber} year ago`}
        </Typography>
      );
    }
    if (dashboardFormat && resultNumber > 1 && resultUnit === "y") {
      return (
        <Typography
          variant={size === "lg" ? "h3" : "caption"}
          className={classes.timeText}
          color="secondary"
        >
          {`${resultNumber} years ago`}
        </Typography>
      );
    }
    return (
      <Typography
        variant={size === "lg" ? "h3" : "caption"}
        className={classes.timeText}
        color="secondary"
      >
        {timeLabel}
      </Typography>
    );
  }

  return <div className={classes.timeContainer}>{formatLabelTransform()}</div>;
}
