import React from "react";
import { makeStyles, Typography, ThemeProvider } from "@material-ui/core";
import ACIcon from "../ACIcon";
import { default as ACTheme } from "../../themes";

const useStyles = makeStyles((theme) => ({
  mainConatiner: {
    display: "flex",
    flexDirection: "row",
    flexShrink: 0,
  },
  shrinkControlContainer: {
    flexShrink: 0,
    width: 500,
    border: "none",
  },
  button: {
    border: "none",
    display: "flex",
    outline: "none",
    backgroundColor: theme.palette.primary.main,
    padding: 0,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: 95,
    borderRadius: "10% 10% 10% 10% / 50% 50% 50% 50%",
    height: 18.5,
    position: "relative",
    cursor: "pointer",
  },
  text: {
    color: "#ffffff",
    fontSize: ".68rem",
    position: "absolute",
    top: 0,
    left: 10,
    height: 18.5,
    paddingTop: 1.5,
  },
}));

export default function NewActivityPill(props) {
  return (
    <ThemeProvider theme={ACTheme}>
      <NewActivityPillContent {...props} />
    </ThemeProvider>
  );
}

function NewActivityPillContent(props) {
  const classes = useStyles();
  const { onClick, disabled } = props;

  return (
    <div className={classes.mainConatiner}>
      <div className={classes.shrinkControlContainer}>
        <button
          className={classes.button}
          onClick={() => onClick()}
          id="newActivityPullButton"
          type="button"
          disabled={disabled}
        >
          <Typography className={classes.text}>New Activity</Typography>
          <div
            style={{
              position: "absolute",
              right: 4,
              top: 0,
            }}
          >
            <ACIcon
              iconname="refresh-outlined"
              style={{
                color: "#ffffff",
                fontSize: 13,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: 18.5,
                margin: 0,
                paddingTop: -2,
              }}
            />
          </div>
        </button>
      </div>
    </div>
  );
}
