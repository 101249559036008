import React from "react";
import {
  makeStyles,
  Paper,
  Typography,
  Divider,
  useMediaQuery,
  ThemeProvider,
} from "@material-ui/core";
import { default as ACTheme } from "../../themes";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 400,
    minWidth: 310,
    height: "fit-content",
    maxHeight: 612,
    padding: 20,
  },
  divider: {
    marginTop: 20,
    marginBottom: 20,
  },
  likesList: {
    display: "flex",
    flex: "auto",
    flexDirection: "column",
    overflowY: "scroll",
    paddingLeft: 10,
  },
  likeContainer: {
    display: "flex",
    flexDirection: "row",
    height: 50,
    width: "auto",
    alignItems: "center",
    marginBottom: 20,
  },
  userImage: {
    width: 50,
    height: 50,
    borderRadius: 50,
    marginRight: 20,
  },
  userDetailsContainer: {
    display: "flex",
    flexDirection: "column",
  },
}));

export default function LikesPopUp(props) {
  return (
    <ThemeProvider theme={ACTheme}>
      <LikesPopUpContent {...props} />
    </ThemeProvider>
  );
}

function Like({ name, title, image }) {
  const isSM = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles(isSM);

  return (
    <div className={classes.likeContainer}>
      <img alt="" className={classes.userImage} src={image} />
      <div className={classes.userDetailsContainer}>
        <Typography variant="body2">{name}</Typography>
        <Typography variant="body1">{title}</Typography>
      </div>
    </div>
  );
}

function LikesPopUpContent({ likes = [] }) {
  const classes = useStyles();

  function renderNumLikesHeader() {
    const totalLikes = likes.length;

    if (totalLikes === 1) {
      return `${totalLikes} Like`;
    }
    return `${totalLikes} Likes`;
  }

  return (
    <Paper className={classes.container}>
      <Typography variant="h2">{renderNumLikesHeader()}</Typography>
      <Divider className={classes.divider} />
      <div className={classes.likesList}>
        {likes.map((like) => (
          <Like
            key={like.image}
            image={like.image}
            name={like.name}
            title={like.title}
          />
        ))}
      </div>
    </Paper>
  );
}
