import React, { useEffect, useState, useCallback } from "react";
import {
  Snackbar as MaterialSnackbar,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { default as ACTheme } from "../../themes";

const useStyles = makeStyles((theme) => {
  return {
    defaultError: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& .MuiAlert-message": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      "& .MuiAlert-icon": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    success: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: theme.palette.primary.main,
      "& .MuiAlert-message": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      "& .MuiAlert-icon": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    variantStandard: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& .MuiAlert-message": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      "& .MuiAlert-icon": {
        color: theme.palette.common.white,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    variantOutlined: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& .MuiAlert-message": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      borderColor: theme.palette.primary.main,
      "& .MuiAlert-icon": {
        color: theme.palette.primary.main,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
  };
});

export default function Snackbar(props) {
  return (
    <ThemeProvider theme={ACTheme}>
      <SnackbarContent {...props} />
    </ThemeProvider>
  );
}

function SnackbarContent(props) {
  const classes = useStyles();
  const { variant, severity, text, onClose, id, onMount } = props;
  const filteredProps = { ...props };
  const [severityWrapper, setSeverityWrapper] = useState(props.severity);
  delete filteredProps.onMount;

  const handleReference = useCallback((ref) => {
    if (onMount) {
      const data = { ref };

      if (id) {
        data.id = id;
      }

      onMount(data);
    }
  });

  useEffect(() => {
    const severitySelector = () => {
      if (severity === "error") setSeverityWrapper("error");
      else if (severity === "warning") setSeverityWrapper("warning");
      else setSeverityWrapper("success");
    };
    severitySelector();
  }, []);

  function classnameSelector() {
    if (severityWrapper === "success") {
      if (variant === "standard") {
        return classes.variantStandard;
      } else if (variant === "outlined") {
        return classes.variantOutlined;
      } else if (variant === "filled") {
        return classes.success;
      }
    } else {
      return classes.defaultError;
    }
  }

  return (
    <MaterialSnackbar
      ref={handleReference}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      onClose={onClose}
      {...props}
    >
      <Alert
        variant={variant}
        severity={severityWrapper}
        onClose={onClose}
        className={classnameSelector()}
      >
        {text}
      </Alert>
    </MaterialSnackbar>
  );
}

Snackbar.defaultProps = {
  variant: "standard",
  severity: "success",
  autoHideDuration: 6000,
};
