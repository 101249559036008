import React from "react";
import {
  makeStyles,
  useMediaQuery,
  Typography,
  ThemeProvider,
} from "@material-ui/core";
import Avatar from "../Avatar";
import { default as ACTheme } from "../../themes";

const useStyles = makeStyles((theme) => ({
  avatarsInvolvedContainer: {
    display: "flex",
    flexDirection: "row",
  },
  textContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: "5px",
    marginTop: -3,
  },
  avatarsContainer: {
    display: "flex",
    flexDirection: "row-reverse",
    marginLeft: "10px",
  },
  avatarImage: {
    marginLeft: "-10px",
  },
  textStyle: {
    color: theme.palette.info.dark,
    fontWeight: 600,
  },
}));

export default function AvatarsInvolved(props) {
  return (
    <ThemeProvider theme={ACTheme}>
      <AvatarsInvolvedContent {...props} />
    </ThemeProvider>
  );
}

function AvatarsInvolvedContent(props) {
  const { avatars, noText, avatarSize, textStyle } = props;
  const isSM = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles(isSM);
  const cleanAvatars = avatars.filter((ava) => ava);

  return (
    <React.Fragment>
      <div className={classes.avatarsInvolvedContainer}>
        <div className={classes.avatarsContainer}>
          {cleanAvatars.length > 0 &&
            cleanAvatars
              .slice(0, cleanAvatars.length > 3 ? 4 : cleanAvatars.length)
              .map((avatar, index) => {
                return (
                  <div key={index} className={classes.avatarImage}>
                    <Avatar
                      image={avatar}
                      size={avatarSize || "sm"}
                      borderColor="lightGrey"
                    />
                  </div>
                );
              })}
        </div>
        {noText ? null : (
          <div className={classes.textContainer}>
            <Typography
              variant="body1"
              className={classes.textStyle}
              style={textStyle || {}}
            >
              {!isSM &&
                `${avatars.length} ${
                  avatars.length === 1 ? "Advisor" : "Advisors"
                }`}
            </Typography>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}
