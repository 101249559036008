import React, { useCallback } from "react";
import { makeStyles, useMediaQuery, ThemeProvider } from "@material-ui/core";
import { ReactComponent as ACBlue } from "./AdvisoryCloudLogo.svg";
import { ReactComponent as ACWhite } from "./AdvisoryCloudLogoLargeWhite.svg";
import { ReactComponent as ACNewWhite } from "./AdvisoryCloudLogoLargeWhite.svg";
import { ReactComponent as ACDark } from "./AdvisoryCloudLogoDark.svg";
import { default as ACTheme } from "../../themes";

const useStyles = makeStyles(() => {
  function getMeasures(props, defaultSize, filter) {
    if (filter === "width") {
      if (props.width) {
        return null;
      }

      return props.height || defaultSize;
    }

    if (props.height) {
      return null;
    }

    return props.width || defaultSize;
  }

  return {
    desktopImage: {
      width: (props) => getMeasures(props, 335, "height"),
      height: (props) => getMeasures(props, 73, "width"),
    },
    mobileImage: {
      width: (props) => getMeasures(props, 250, "height"),
      height: (props) => getMeasures(props, 55, "width"),
    },
    pointerCursor: {
      cursor: "pointer",
    },
  };
});

export default function AdvisoryCloudLogo(props) {
  return (
    <ThemeProvider theme={ACTheme}>
      <AdvisoryCloudLogoContent {...props} />
    </ThemeProvider>
  );
}

function AdvisoryCloudLogoContent(props) {
  const {
    height,
    id,
    link,
    removeHyperlink,
    onClick,
    onMount,
    width,
    variant,
    cursor,
  } = props;

  if (width && height) {
    console.error("AdvisoryCloudLogo: Just can provide width or height");
    return null;
  }

  const classes = useStyles(props);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const handleReference = useCallback((ref) => {
    if (onMount) {
      const data = { ref };

      if (id) {
        data.id = id;
      }

      onMount(data);
    }
  });

  if (removeHyperlink) {
    if (variant === "white") {
      return (
        <ACWhite
          alt="AdvisoryCloud Logo"
          className={isMobile ? classes.mobileImage : classes.desktopImage}
          onClick={() => onClick()}
          style={{ cursor: cursor || "pointer" }}
        />
      );
    }
    if (variant === "newWhite") {
      return (
        <ACNewWhite
          alt="AdvisoryCloud Logo"
          className={isMobile ? classes.mobileImage : classes.desktopImage}
          onClick={() => onClick()}
          style={{ cursor: cursor || "pointer" }}
        />
      );
    }
    if (variant === "dark") {
      return (
        <ACDark
          alt="AdvisoryCloud Logo"
          className={isMobile ? classes.mobileImage : classes.desktopImage}
          onClick={() => onClick()}
          style={{ cursor: cursor || "pointer" }}
        />
      );
    }
    return (
      <ACBlue
        alt="AdvisoryCloud Logo"
        className={isMobile ? classes.mobileImage : classes.desktopImage}
        onClick={() => onClick()}
        style={{ cursor: cursor || "pointer" }}
      />
    );
  }

  if (variant === "white") {
    return (
      <a ref={handleReference} href={link || "https://www.advisorycloud.com"}>
        <ACWhite
          alt="AdvisoryCloud Logo"
          className={isMobile ? classes.mobileImage : classes.desktopImage}
        />
      </a>
    );
  }
  if (variant === "newWhite") {
    return (
      <a ref={handleReference} href={link || "https://www.advisorycloud.com"}>
        <ACNewWhite
          alt="AdvisoryCloud Logo"
          className={isMobile ? classes.mobileImage : classes.desktopImage}
        />
      </a>
    );
  }
  if (variant === "dark") {
    return (
      <a ref={handleReference} href={link || "https://www.advisorycloud.com"}>
        <ACDark
          alt="AdvisoryCloud Logo"
          className={isMobile ? classes.mobileImage : classes.desktopImage}
        />
      </a>
    );
  }

  return (
    <a
      aria-label="ACLogo"
      form="logo"
      ref={handleReference}
      href={link || "https://www.advisorycloud.com"}
      style={{ cursor: cursor || "pointer" }}
    >
      <ACBlue
        alt="AdvisoryCloud Logo"
        className={isMobile ? classes.mobileImage : classes.desktopImage}
      />
    </a>
  );
}
