import React from "react";
import { makeStyles, useMediaQuery, Paper, ThemeProvider } from "@material-ui/core";
import PostCardBottom from "../PostCardBottom";
import PostCardHeader from "./PostCardHeader";
import PostCardBody from "./PostCardBody";
import { default as ACTheme } from "../../themes";

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: "transparent",
    borderRadius: 0,
    width: "100%",
  },
  separator: {
    marginBottom: "15px",
  },
}));

export default function PostCard(props) {
  return (
    <ThemeProvider theme={ACTheme}>
      <PostCardContent {...props} />
    </ThemeProvider>
  );
}

const PostCardContent = (props) => {
  const {
    // body
    body,
    video,
    attachments,
    primaryAttachment,
    // likes
    likes,
    likesClick,
    likesFilled,
    likesDisabled,
    // answers
    answers,
    answersClick,
    answersFilled,
    answersBadge,
    answersBadgeContent,
    answersDisabled,
    // comments
    comments,
    commentsClick,
    commentsFilled,
    commentsBadge,
    commentsBadgeContent,
    commentsDisabled,
    // breakthroughs
    breakthroughs,
    breakthroughsClick,
    breakthroughsFilled,
    breakthroughsGold,
    breakthroughsBold,
    breakthroughsDisabled,
    // service clicks
    editClick,
    deleteClick,
    pinClick,
    isPinned,
    bodyClick,
    bodyDisabled,
    // date and preview format
    type,
    dashboardFormat,
    createdAt,
    // avatars & others
    contributorAvatars,
    companyName,
    companyAvatar,
    contributorsClick,
    contributorsDisabled,
    noEllipsis,
    // extra text
    initialBoldText,
    linkAtTheEnd,
    welcomePostStaticClasses,
    //extras
    isFirst,
    isOpportunityView,
  } = props;
  const isSM = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles(isSM);

  function footerType() {
    if (type === "update") {
      return (
        <PostCardBottom
          size={isSM ? "xs" : "lg"}
          // likes
          likes={likes}
          likesClick={likesClick}
          likesFilled={likesFilled}
          likesDisabled={likesDisabled}
          // comments
          comments={comments}
          commentsClick={commentsClick}
          commentsFilled={commentsFilled}
          commentsBadge={commentsBadge}
          commentsBadgeContent={commentsBadgeContent}
          commentsDisabled={commentsDisabled}
          //service props
          avatars={contributorAvatars}
          //clicks
          contributorsClick={contributorsClick}
          contributorsDisabled={contributorsDisabled}
          welcomePostStaticClasses={welcomePostStaticClasses}
          //extras
          isFirst={isFirst}
        />
      );
    }
    if (type === "question") {
      return (
        <PostCardBottom
          size={isSM ? "xs" : "lg"}
          // likes
          likes={likes}
          likesClick={likesClick}
          likesFilled={likesFilled}
          likesDisabled={likesDisabled}
          // answers
          answers={answers}
          answersClick={answersClick}
          answersFilled={answersFilled}
          answersDisabled={answersDisabled}
          answersBadge={answersBadge}
          answersBadgeContent={answersBadgeContent}
          //comments
          comments={comments}
          commentsClick={commentsClick}
          commentsFilled={commentsFilled}
          commentsBadge={commentsBadge}
          commentsBadgeContent={commentsBadgeContent}
          commentsDisabled={commentsDisabled}
          // breakthroughs
          breakthroughs={breakthroughs}
          breakthroughsClick={breakthroughsClick}
          breakthroughsFilled={breakthroughsFilled}
          breakthroughsGold={breakthroughsGold}
          breakthroughsBold={breakthroughsBold}
          breakthroughsDisabled={breakthroughsDisabled}
          //extras
          isFirst={isFirst}
          // service
          avatars={contributorAvatars}
          contributorsClick={contributorsClick}
          contributorsDisabled={contributorsDisabled}
          welcomePostStaticClasses={welcomePostStaticClasses}
        />
      );
    }
    return null;
  }

  function arrayLogic(data) {
    if (data === null || data === undefined) {
      return null;
    }
    return data;
  }

  return (
    <Paper elevation={0} className={classes.card}>
      <div className={classes.separator}>
        <PostCardHeader
          type={type}
          initialTime={createdAt}
          companyName={companyName}
          companyAvatar={companyAvatar}
          isOpportunityView={isOpportunityView}
          dashboardFormat={dashboardFormat ? true : false}
          editClick={editClick}
          pinClick={pinClick}
          isPinned={isPinned}
          deleteClick={deleteClick}
          noEllipsis={noEllipsis}
        />
      </div>
      <div className={classes.separator}>
        <PostCardBody
          body={body}
          bodyClick={bodyClick}
          attachments={arrayLogic(attachments)}
          video={video}
          dashboardFormat={dashboardFormat}
          bodyDisabled={bodyDisabled}
          primaryAttachment={primaryAttachment}
          initialBoldText={initialBoldText}
          linkAtTheEnd={linkAtTheEnd}
        />
      </div>
      {footerType()}
    </Paper>
  );
};
