import React from "react";
import { makeStyles, ThemeProvider } from "@material-ui/core";
import ACIcon from "../ACIcon/index";
import { default as ACTheme } from "../../themes";

const useStyles = makeStyles({
  elementsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    flexShrink: 1,
    width: "fit-content",
    height: "fit-content",
    position: "relative",
  },

  xsImage: {
    borderRadius: "50%",
    height: "20px",
    width: "20px",
  },
  compactImage: {
    borderRadius: "50%",
    height: "44px",
    width: "44px",
  },
  smallImage: {
    borderRadius: "50%",
    height: "34px",
    width: "34px",
  },
  mediumImage: {
    borderRadius: "50%",
    height: "50px",
    width: "50px",
  },
  adminUserCard: {
    borderRadius: "50%",
    height: "55px",
    width: "55px",
  },
  largeImage: {
    borderRadius: "50%",
    height: "85px",
    width: "85px",
  },
  largeMobileImage: {
    borderRadius: "50%",
    height: "73px",
    width: "73px",
  },
  extraLargeImage: {
    borderRadius: "50%",
    height: "100px",
    width: "100px",
  },
  extraExtraLargeImage: {
    borderRadius: "50%",
    height: "120px",
    width: "120px",
  },
  starButtonCompact: {
    borderRadius: "50%",
    background: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    outline: "none",
    border: "none",
    height: "18px",
    width: "18px",
    left: "-10px",
    top: "-12px",
    cursor: "pointer",
    zIndex: 999,
    position: "absolute",
  },
  starButton: {
    borderRadius: "50%",
    background: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    outline: "none",
    border: "none",
    height: "18px",
    width: "18px",
    left: "-10px",
    top: "-5px",
    cursor: "pointer",
    zIndex: 999,
    position: "absolute",
  },
  starButtonXl: {
    borderRadius: "50%",
    background: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    outline: "none",
    border: "none",
    height: "35px",
    width: "35px",
    left: "-10px",
    top: "-18px",
    cursor: "pointer",
    position: "absolute",
    zIndex: 999,
  },
  starIconFilled: {
    fontSize: "20px",
    color: "#FFC400",
    zIndex: 999,
  },
  starIconFilledCompact: {
    fontSize: "15px",
    color: "#FFC400",
    zIndex: 999,
  },
  starIconEmpty: {
    fontSize: "20px",
    color: "#C9C9DA",
    zIndex: 999,
  },
  starIconEmptyCompact: {
    fontSize: "15px",
    color: "#C9C9DA",
    zIndex: 999,
  },
});

export default function Avatar(props) {
  return (
    <ThemeProvider theme={ACTheme}>
      <AvatarContent {...props} />
    </ThemeProvider>
  );
}

function AvatarContent(props) {
  const {
    avatarClick,
    size,
    image,
    isStar,
    isStarFilled,
    onClick,
    borderColor,
  } = props;
  const classes = useStyles();

  function starSelector() {
    if (size === "compact" && isStar && isStarFilled) {
      return (
        <ACIcon
          iconname="star"
          classes={{ root: classes.starIconFilledCompact }}
        />
      );
    }
    if (size === "md" && isStar && isStarFilled) {
      return (
        <ACIcon iconname="star" classes={{ root: classes.starIconFilled }} />
      );
    }
    if (size === "lg-mobile" && isStar && isStarFilled) {
      return (
        <ACIcon iconname="star" classes={{ root: classes.starIconFilled }} />
      );
    }
    if (size === "xl" && isStar && isStarFilled) {
      return (
        <ACIcon iconname="star" classes={{ root: classes.starIconFilled }} />
      );
    }
    if (size === "compact" && isStar) {
      return (
        <ACIcon
          iconname="star-outlined"
          classes={{ root: classes.starIconEmptyCompact }}
        />
      );
    }
    if (size === "md" && isStar) {
      return (
        <ACIcon
          iconname="star-outlined"
          classes={{ root: classes.starIconEmpty }}
        />
      );
    }
    if (size === "lg-mobile" && isStar) {
      return (
        <ACIcon
          iconname="star-outlined"
          classes={{ root: classes.starIconEmpty }}
        />
      );
    }
    if (size === "xl" && isStar) {
      return (
        <ACIcon
          iconname="star-outlined"
          classes={{ root: classes.starIconEmpty }}
        />
      );
    }
    return null;
  }

  function sizeSelector() {
    if (size === "compact") {
      return classes.compactImage;
    }
    if (size === "xs") {
      return classes.xsImage;
    }
    if (size === "sm") {
      return classes.smallImage;
    }
    if (size === "md") {
      return classes.mediumImage;
    }
    if (size === "adminUserCard") {
      return classes.adminUserCard;
    }
    if (size === "lg") {
      return classes.largeImage;
    }
    if (size === "lg-mobile") {
      return classes.largeMobileImage;
    }
    if (size === "xl") {
      return classes.extraLargeImage;
    }
    if (size == "xxl") return classes.extraExtraLargeImage;
  }

  function borderColorSelection() {
    if (!borderColor || borderColor === null || borderColor === undefined) {
      return "none";
    }
    if (borderColor === "white") {
      return ".5px solid #ffffff";
    }
    if (borderColor === "grey") {
      return ".5px solid #767680";
    }
    if (borderColor === "lightGrey") {
      return ".5px solid #E6EDFE";
    }
    return "none";
  }

  function starButtonClassSelection() {
    if (size === "xl") {
      return classes.starButtonXl;
    }
    if (size === "compact") {
      return classes.starButtonCompact;
    }
    return classes.starButton;
  }

  return (
    <div className={classes.elementsContainer}>
      {isStar &&
      (size === "md" ||
        size === "xl" ||
        size === "lg-mobile" ||
        size === "compact") ? (
        <button
          type="button"
          className={starButtonClassSelection()}
          onClick={(event) => {
            onClick();
            event.stopPropagation();
          }}
        >
          {starSelector()}
        </button>
      ) : null}
      <img
        alt=""
        className={sizeSelector()}
        style={{
          border: borderColorSelection(),
          cursor: avatarClick ? "pointer" : "auto",
        }}
        onClick={avatarClick ? avatarClick : () => {}}
        src={image}
      />
    </div>
  );
}
