import React, { useEffect, useState, useRef } from "react";
import { makeStyles, Typography, Link } from "@material-ui/core";
import Carousel from "../../Carousel";
import CarouselCard from "../../CarosuelCard";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    width: "100%",
    height: "fit-content",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    flexDirection: "column",
  },
  group: {
    display: "flex",
    width: "100%",
    height: "fit-content",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  bodyDashboard: {
    display: "inline-block",
    width: "100%",
    maxHeight: "107px",
    overflow: "hidden",
    position: "relative",
    overflowWrap: "break-word",
    wordBreak: "break-word",
    hyphens: "auto",
    color: theme.palette.info.dark,
  },
  bodyIndividual: {
    maxWidth: "100%",
    display: "inline-block",
    height: "fit-content",
    overflowWrap: "normal",
    wordBreak: "normal",
    color: theme.palette.info.dark,
  },
  more: {
    position: "absolute",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    height: "fit-content",
    width: "63px",
    bottom: 0,
    right: 0,
    background:
      "linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 7%, rgba(255,255,255,1) 100%)",
  },
  text: {
    color: theme.palette.secondary.dark,
  },
  preview: {
    width: "100%",
    height: "310px",
    borderColor: theme.palette.secondary.main,
    borderRadius: "5px",
    marginBottom: "10px",
    display: "flex",
  },
  videoPreview: {
    position: "relative",
    paddingBottom: "56.25%",
    height: 0,
    width: "100%",
    overflow: "hidden",
    marginTop: 10,
  },
  videoFrame: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
  previewImage: {
    height: "100%",
    borderRadius: "5px",
    margin: 0,
    paddin: 0,
  },
  carouselContainer: {
    display: "flex",
    flexDirection: "column",
    minHeight: "95px",
    width: "100%",
  },
  buttonBody: {
    borderRadius: "5px",
    border: "none",
    outline: "none",
    cursor: "pointer",
    "&:disabled": {
      cursor: "default",
    },
    backgroundColor: "transparent",
    width: "100%",
    margin: 0,
    padding: 0,
  },
}));

function PostCardBody(props) {
  const {
    body,
    video,
    dashboardFormat,
    attachments,
    bodyClick,
    bodyDisabled,
    primaryAttachment,
    initialBoldText,
    linkAtTheEnd,
  } = props;
  const classes = useStyles();
  const [isOverflowing, setIsOverflowing] = useState();
  const [selectedSrc, setSelectedSrc] = useState({ primaryAttachment });
  const [firstMounted, setFirstMounted] = useState(true);
  const [mouseOverLink, setMouseOverLink] = useState(false);
  const elementRef = useRef();

  function isOverflowed(element) {
    return (
      element.scrollHeight > element.clientHeight ||
      element.scrollWidth > element.clientWidth
    );
  }

  useEffect(() => {
    const resultsOverflow = isOverflowed(elementRef.current);
    setIsOverflowing(resultsOverflow);
  }, [isOverflowing]);

  function selectArrayElement(data) {
    if (data === -1) {
      setSelectedSrc("");
    } else {
      if (firstMounted) {
        setSelectedSrc(primaryAttachment);
      } else {
        setSelectedSrc(attachments[data]);
      }
    }
    setFirstMounted(false);
  }

  function renderVideo() {
    if (video && video.length !== 0) {
      return (
        <div className={classes.videoPreview}>
          <iframe
            src={video}
            title="postVideo"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            className={classes.videoFrame}
          />
        </div>
      );
    }
    return null;
  }
  function renderAttachments() {
    if (
      attachments === null ||
      attachments === undefined ||
      attachments.length === 0
    ) {
      return null;
    }

    if (attachments.length === 1 && dashboardFormat) {
      return (
        <button
          type="button"
          className={classes.buttonBody}
          onClick={() => bodyClick()}
          disabled={bodyDisabled}
          style={{ paddingTop: "9px" }}
        >
          <div className={classes.preview}>
            <img src={attachments[0]} alt="" className={classes.previewImage} />
          </div>
        </button>
      );
    }

    if (attachments.length > 1 && dashboardFormat) {
      return (
        <button
          type="button"
          className={classes.buttonBody}
          onClick={() => bodyClick()}
          disabled={bodyDisabled}
          style={{ paddingTop: "9px" }}
        >
          <div className={classes.preview}>
            <img
              src={primaryAttachment}
              alt=""
              className={classes.previewImage}
            />
          </div>
        </button>
      );
    }

    if (attachments.length > 1 && !dashboardFormat) {
      return (
        <React.Fragment>
          <div className={classes.preview}>
            <img src={selectedSrc} alt="" className={classes.previewImage} />
          </div>

          <section className={classes.carouselContainer}>
            <Carousel slideSelected={selectArrayElement}>
              {attachments.map((slide) => {
                return <CarouselCard key={slide} img={slide} />;
              })}
            </Carousel>
          </section>
        </React.Fragment>
      );
    }

    if (attachments.length === 1 && !dashboardFormat) {
      return (
        <div className={classes.preview}>
          <img src={attachments[0]} alt="" className={classes.previewImage} />
        </div>
      );
    }

    return null;
  }

  function formatBody(text) {
    const textArray = text.split(" ");
    const elementsArray = [];
    let wordBucket = [];
    if (initialBoldText) {
      const boldArray = initialBoldText.split("\n");

      boldArray.forEach((boldWord, index) => {
        elementsArray.push(
          <span key={`extra01bold${index}`} style={{ fontWeight: "bold" }}>
            {boldWord}
          </span>
        );
        if (index !== boldArray.length - 1) {
          elementsArray.push(<br key={`brBold${index}`} />);
        }
      });
      elementsArray.push(<span key={"extra02"}> </span>);
    }
    textArray.forEach((word, index) => {
      if (
        word.startsWith("https://") ||
        word.startsWith("http://") ||
        word.includes(`\n`)
      ) {
        elementsArray.push(
          <span key={`${index}s1`}>{wordBucket.join(" ")}</span>
        );
        elementsArray.push(<span key={`${index}s2`}> </span>);
        if (!word.includes(`\n`)) {
          elementsArray.push(
            <Link
              key={`${index}l1`}
              href={word}
              target="_blank"
              onMouseOver={() => setMouseOverLink(true)}
              onMouseLeave={() => setMouseOverLink(false)}
            >
              {word}
            </Link>
          );
          elementsArray.push(<span key={`${index}s3`}> </span>);
        } else {
          let separatedWord = word.split(`\n`);
          separatedWord.forEach((piece, brIndex) => {
            if (piece.startsWith("https://") || piece.startsWith("http://")) {
              elementsArray.push(
                <Link
                  key={`${brIndex}link${index}`}
                  href={piece}
                  target="_blank"
                  onMouseOver={() => setMouseOverLink(true)}
                  onMouseLeave={() => setMouseOverLink(false)}
                >
                  {piece}
                </Link>
              );
            } else if (piece !== "") {
              elementsArray.push(
                <span key={`${index}piece${brIndex}`}>{piece}</span>
              );
            }
            if (brIndex !== separatedWord.length - 1) {
              elementsArray.push(<br key={`${brIndex}br${index}`} />);
            } else {
              elementsArray.push(<span key={`${index}sp${brIndex}`}> </span>);
            }
          });
        }

        wordBucket = [];
      } else {
        wordBucket.push(word);
      }
    });

    elementsArray.push(<span key="finalItem">{wordBucket.join(" ")}</span>);
    if (linkAtTheEnd) {
      elementsArray.push(<span key={"extra03"}> </span>);
      elementsArray.push(
        <Link
          key={"extra04"}
          href={linkAtTheEnd.targetUrl}
          target={linkAtTheEnd.newTab ? "_blank" : "_self"}
          onMouseOver={() => setMouseOverLink(true)}
          onMouseLeave={() => setMouseOverLink(false)}
        >
          {linkAtTheEnd.text}
        </Link>
      );
    }
    return (
      <React.Fragment>{elementsArray.map((element) => element)}</React.Fragment>
    );
  }

  function renderBody() {
    if (dashboardFormat) {
      return (
        <div className={classes.mainContainer}>
          <button
            type="button"
            className={classes.buttonBody}
            onClick={() => (mouseOverLink ? () => {} : bodyClick())}
            disabled={bodyDisabled}
          >
            <div className={classes.group}>
              <Typography
                align="left"
                ref={elementRef}
                className={classes.bodyDashboard}
              >
                {formatBody(body)}
                {isOverflowing ? (
                  <Typography component="span" className={classes.more}>
                    ...
                    <Typography component="span" className={classes.text}>
                      &nbsp; More
                    </Typography>
                  </Typography>
                ) : null}
              </Typography>
            </div>
          </button>
          {renderVideo()}
          {renderAttachments()}
        </div>
      );
    }
    return (
      <div className={classes.mainContainer}>
        <div className={classes.group}>
          <Typography
            ref={elementRef}
            id="bodyParagraph"
            className={classes.bodyIndividual}
            align="left"
          >
            {formatBody(body)}
          </Typography>
        </div>
        {renderVideo()}
        {renderAttachments()}
      </div>
    );
  }

  return <React.Fragment>{renderBody()}</React.Fragment>;
}

export default PostCardBody;
