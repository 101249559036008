import React from "react";
import {
  makeStyles,
  useMediaQuery,
  Paper,
  Typography,
  CircularProgress,
  ThemeProvider,
} from "@material-ui/core";
import Button from "../Button";
import PostCardBottom from "../PostCardBottom";
import AdvisorDetailHeader from "./AdvisorDetailHeader";
import AnswerBody from "./AdvisorDetailBody";
import { default as ACTheme } from "../../themes";

function SetCardPadding(sizes) {
  if (sizes.isXS) {
    return 5;
  }
  if (sizes.isSM) {
    return 10;
  }
  return 15;
}

function SetSeparatorLineWidth(sizes) {
  if (sizes.isXS) {
    return "calc(100% + 10px)";
  }
  if (sizes.isSM) {
    return "calc(100% + 20px)";
  }
  return "calc(100% + 30px)";
}

function SetSeparatorLineMarginLeft(sizes) {
  if (sizes.isXS) {
    return "-5px";
  }
  if (sizes.isSM) {
    return "-10px";
  }
  return "-15px";
}

const useStyles = makeStyles((theme) => ({
  dNone: {
    display: "none",
  },
  progression: {
    position: "absolute",
    left: "50%",
    bottom: "60%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 2,
    border: "none",
  },
  bgColor: {
    backgroundColor: theme.palette.common.white,
  },
  loadingColor: {
    backgroundColor: "rgba(0,0,0,0.4)",
  },
  card: {
    padding: (sizes) => SetCardPadding(sizes),
    borderRadius: 5,
    display: "flex",
    flexDirection: "column",
    height: "fit-content",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    cursor: (sizes) => (sizes.isCardClickable ? "pointer" : "auto"),
    border: `1px solid ${theme.palette.primary.light}`,
  },
  separator: {
    width: "100%",
    marginTop: 10,
  },
  separatorLine: {
    width: (sizes) => SetSeparatorLineWidth(sizes),
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
    marginLeft: (sizes) => SetSeparatorLineMarginLeft(sizes),
    marginTop: "1em",
  },
  postCardBottom: {
    marginTop: "10px",
  },
}));

export default function AdvisorDetailCard(props) {
  return (
    <ThemeProvider theme={ACTheme}>
      <AdvisorDetailCardContent {...props} />
    </ThemeProvider>
  );
}

const AdvisorDetailCardContent = (props) => {
  const {
    // answers
    answers,
    answersClick,
    answersFilled,
    answersBadge,
    answersBadgeContent,
    answersDisabled,
    // comments
    comments,
    commentsClick,
    commentsFilled,
    commentsBadge,
    commentsBadgeContent,
    commentsDisabled,
    // breakthroughs
    breakthroughs,
    breakthroughsClick,
    breakthroughsFilled,
    breakthroughsBadge,
    breakthroughBadgeContent,
    breakthroughsGold,
    breakthroughsBold,
    breakthroughsDisabled,
    // service clicks
    cardClick,
    isCardClickable,
    requestMeetingClick,
    requestMeetingAlternateText,
    inviteToJoinClick,
    reportAdvisorClick,
    isStar,
    starClicked,
    isStarFilled,
    // highlighter
    profileHighlighter,
    profileHighlighterType,
    profileHighlighterDate,
    profileHighlighterAlternateSettings,
    ProfileHighlighterCustomIcon,
    meetingHighlighter,
    meetingHighlighterType,
    MeetingHighlighterCustomIcon,
    // advisorDetail
    advisorName,
    advisorInfo,
    advisorAvatar,
    advisorCompany,
    skillsAboveText,
    skills,
    bio,
    bioAlternateTitle,
    bioLinkOpenBlank,
    bioLinkTarget,
    bioLinkAsClickAction,
    bioLinkClickAction,
    bioMaxLength,
    bioShowLink,
    invitedToJoinDate,
    formerBoardMember,
    isVerticalElipsis,
    buttonTitles,
    isLoading,
    removeBelowButtons,
    removeBio,
    addtopclasses,
    boardMeetingStaticClasses,
  } = props;
  const isSM = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isXS = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const classes = useStyles({ isSM, isXS, isCardClickable });

  function buttonHeight() {
    if (isXS) {
      return 35;
    }
    if (isSM) {
      return 23;
    }
    if (requestMeetingAlternateText) {
      return 33;
    }
    return 25;
  }

  function actionButtonWidth() {
    if (isSM) {
      return 80;
    }
    return 100;
  }

  function renderOpportunityButton() {
    let button = !buttonTitles ? (
      <Button
        style={{
          borderRadius: "4px 4px 4px 4px",
          height: buttonHeight(),
          marginTop: isSM ? "0px" : "2px",
          minWidth: requestMeetingAlternateText ? "140px" : "",
        }}
        color="primary"
        size="small"
        width="100%"
        onClick={(event) => {
          requestMeetingClick();
          event.stopPropagation();
        }}
      >
        <Typography variant="caption" className={classes.requestMeetingText}>
          {requestMeetingAlternateText || "Request Meeting"}
        </Typography>
      </Button>
    ) : (
      <Button
        style={{
          borderRadius: "4px 4px 4px 4px",
          height: buttonHeight(),
          marginTop: isSM ? "0px" : "2px",
        }}
        color="primary"
        size="small"
        width={actionButtonWidth()}
        isAction={true}
        buttonTitles={buttonTitles}
      >
        Actions
      </Button>
    );

    return isXS ? button : null;
  }

  return (
    <React.Fragment>
      <Paper
        onClick={cardClick}
        elevation={0}
        className={`${classes.card} ${
          !removeBelowButtons && isLoading
            ? classes.loadingColor
            : classes.bgColor
        }${addtopclasses ? " opportunityTop" : ""}${
          boardMeetingStaticClasses ? " boardMeetingOpp" : ""
        }`}
      >
        <div className={classes.separator}>
          <AdvisorDetailHeader
            advisorName={advisorName}
            // TODO endpoint to get description.
            advisorInfo={advisorInfo}
            advisorCompany={advisorCompany}
            advisorAvatar={advisorAvatar}
            skillsAboveText={skillsAboveText}
            skills={skills}
            invitedToJoinDate={invitedToJoinDate}
            formerBoardMember={formerBoardMember}
            requestMeetingClick={requestMeetingClick}
            requestMeetingAlternateText={requestMeetingAlternateText}
            inviteToJoinClick={inviteToJoinClick}
            reportAdvisorClick={reportAdvisorClick}
            isStar={isStar}
            starClicked={starClicked}
            isStarFilled={isStarFilled}
            profileHighlighter={profileHighlighter}
            profileHighlighterAlternateSettings={
              profileHighlighterAlternateSettings
            }
            ProfileHighlighterCustomIcon={ProfileHighlighterCustomIcon}
            profileHighlighterType={profileHighlighterType}
            profileHighlighterDate={profileHighlighterDate}
            meetingHighlighter={meetingHighlighter}
            meetingHighlighterType={meetingHighlighterType}
            MeetingHighlighterCustomIcon={MeetingHighlighterCustomIcon}
            isVerticalElipsis={isVerticalElipsis}
            buttonTitles={buttonTitles}
            addtopclasses={addtopclasses}
            boardMeetingStaticClasses={boardMeetingStaticClasses}
          />
        </div>
        <React.Fragment>
          {!removeBio && (
            <div className={classes.separator}>
              <CircularProgress
                className={!isLoading ? classes.dNone : classes.progression}
              />
              <AnswerBody
                bio={bio}
                bioAlternateTitle={bioAlternateTitle}
                bioLinkAsClickAction={bioLinkAsClickAction}
                bioLinkClickAction={bioLinkClickAction}
                bioLinkTarget={bioLinkTarget}
                bioLinkOpenBlank={bioLinkOpenBlank}
                bioMaxLength={bioMaxLength}
                bioShowLink={bioShowLink}
                isLoading={isLoading}
              />
            </div>
          )}
        </React.Fragment>
        <React.Fragment>
          {renderOpportunityButton()}
          {!removeBelowButtons && (
            <React.Fragment>
              <div className={classes.separatorLine} />
              <div className={classes.postCardBottom}>
                <PostCardBottom
                  size={isSM ? "xs" : "lg"}
                  iconSeparation={30}
                  // answers
                  answers={answers}
                  answersClick={answersClick}
                  answersFilled={answersFilled}
                  answersBadge={answersBadge}
                  answersBadgeContent={answersBadgeContent}
                  answersDisabled={answersDisabled}
                  // comments
                  comments={comments}
                  commentsClick={commentsClick}
                  commentsFilled={commentsFilled}
                  commentsBadge={commentsBadge}
                  commentsBadgeContent={commentsBadgeContent}
                  commentsDisabled={commentsDisabled}
                  // breakthroughs
                  breakthroughs={breakthroughs}
                  breakthroughsClick={breakthroughsClick}
                  breakthroughsFilled={breakthroughsFilled}
                  breakthroughsBadge={breakthroughsBadge}
                  breakthroughBadgeContent={breakthroughBadgeContent}
                  breakthroughsGold={breakthroughsGold}
                  breakthroughsBold={breakthroughsBold}
                  breakthroughsDisabled={breakthroughsDisabled}
                />
              </div>
            </React.Fragment>
          )}
        </React.Fragment>
      </Paper>
    </React.Fragment>
  );
};
