import React from "react";
import { makeStyles, Typography, useMediaQuery } from "@material-ui/core";
import ACIcon from "../../../ACIcon";

const useStyles = makeStyles((theme) => ({
  text: {
    fontWeight: "500",
    fontSize: (isSM) => (isSM ? "0.7rem" : "0.8rem"),
  },
  iconStyle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    fontSize: (isSM) => (isSM ? 20 : 25),
    marginRight: "3px",
    color: theme.palette.primary.main,
  },
  iconQuestionStyle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    fontSize: (isSM) => (isSM ? 20 : 25),
    marginRight: "3px",
    color: theme.palette.primary.main,
  },
  contentContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexShrink: 0,
    width: "fit-content",
    height: "fit-content",
  },
  mainContainer: {
    display: "flex",
    flexShrink: 0,
  },
}));

function PostTypeDecorator(props) {
  const { type } = props;
  const isSM = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles(isSM);

  const Question = () => {
    return (
      <div className={classes.contentContainer}>
        <ACIcon
          classes={{ root: classes.iconQuestionStyle }}
          iconname="questions"
        />
        <Typography color="primary" className={classes.text}>
          Question
        </Typography>
      </div>
    );
  };

  const Update = () => {
    return (
      <div className={classes.contentContainer}>
        <ACIcon classes={{ root: classes.iconStyle }} iconname="updates" />
        <Typography color="primary" className={classes.text}>
          Update
        </Typography>
      </div>
    );
  };

  function renderContents() {
    if (type === "question") {
      return <Question />;
    }
    if (type === "update") {
      return <Update />;
    }
    return null;
  }

  return <div className={classes.mainContainer}>{renderContents()}</div>;
}

export default PostTypeDecorator;
