import React from "react";
import {
  makeStyles,
  Typography,
  useMediaQuery,
  ThemeProvider,
} from "@material-ui/core";
import Avatar from "../Avatar";
import ProfileHighlighterPill from "../ProfileHighlighterPill";
import { default as ACTheme } from "../../themes";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "transparent",
    width: "fit-content",
    height: "fit-content",
  },
  boardMeetingContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "transparent",
    width: (isSM) => (isSM ? "50%" : "fit-content"),
    height: "fit-content",
  },
  titleName: {
    color: theme.palette.info.dark,
    fontWeight: 500,
    padding: 0,
    margin: 0,
    letterSpacing: "-.3px",
    fontFamily: "Poppins",
  },
  highlighterPill: {
    marginLeft: "5px",
    marginTop: "1.75px",
  },
  subtitle: {
    color: theme.palette.secondary.dark,
    fontWeight: 400,
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    width: "auto",
    marginLeft: "10px",
  },
  titleNameContainer: {
    display: "flex",
    width: "auto",
  },
  subtitleContainer: {
    display: "flex",
    width: "auto",
  },
}));

export default function PostHeaderAvatar(props) {
  return (
    <ThemeProvider theme={ACTheme}>
      <PostHeaderAvatarContent {...props} />
    </ThemeProvider>
  );
}

function PostHeaderAvatarContent(props) {
  const {
    image,
    titleName,
    subtitle,
    avatarBorderColor,
    avatarSize,
    fontSize,
    isBoardMeeting,
    fontSeparation,
    isStar,
    isStarFilled,
    starClicked,
    ProfileHighlighterType,
    avatarClick,
  } = props;
  const isSM = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles(isSM);

  function renderDays() {
    if (subtitle === null || subtitle === undefined) {
      return <React.Fragment>&nbsp;</React.Fragment>;
    }
    return subtitle;
  }

  function renderSize() {
    if (avatarSize === null || avatarSize === undefined) {
      return "md";
    }
    return avatarSize;
  }

  function subtitleSize() {
    if (fontSize === "lg") {
      return "h1";
    }
    if (fontSize === "sm") {
      return "body1";
    }
    if (fontSize === "xs") {
      return "caption";
    }
    return "body1";
  }

  function titleSize() {
    if (fontSize === "lg") {
      return "h2";
    }
    if (fontSize === "sm") {
      return "body2";
    }
    if (fontSize === "xs") {
      return "caption";
    }
    return "body2";
  }

  return (
    <div
      className={
        isBoardMeeting ? classes.boardMeetingContainer : classes.container
      }
    >
      <Avatar
        image={image}
        avatarClick={avatarClick}
        size={renderSize()}
        borderColor={avatarBorderColor}
        isStar={isStar}
        isStarFilled={isStarFilled}
        onClick={starClicked}
      />
      <div
        className={classes.textContainer}
        style={{
          cursor: avatarClick ? "pointer" : "auto",
        }}
        onClick={avatarClick ? avatarClick : () => {}}
      >
        <div className={classes.titleNameContainer}>
          <Typography variant={titleSize()} className={classes.titleName}>
            {titleName}
          </Typography>
          {ProfileHighlighterType ? (
            <div className={classes.highlighterPill}>
              <ProfileHighlighterPill type={ProfileHighlighterType} noLogo />
            </div>
          ) : null}
        </div>
        <div
          className={classes.subtitleContainer}
          style={{ marginTop: fontSeparation ? fontSeparation : 1 }}
        >
          <Typography
            component="div"
            variant={subtitleSize()}
            className={classes.subtitle}
          >
            {renderDays()}
          </Typography>
        </div>
      </div>
    </div>
  );
}
