import React from "react";
import { makeStyles, ThemeProvider } from "@material-ui/core";
import TitleAndContent from "../TitleAndContent";
import Button from "../Button";
import { default as ACTheme } from "../../themes";

const useStyles = makeStyles({
  container: {
    width: "100%",
    textAlign: "center",
  },
  imageContainer: {
    margin: "0px auto",
    marginBottom: "100px",
    display: "inline-block",
  },
  image: {
    borderRadius: "50%",
    display: "inline-block",
    margin: "0px 10px",
    marginBottom: "10px",
    background: "white",
  },
  button: {
    margin: "auto",
    display: "inline-block",
  },
  titleAndContent: {
    marginBottom: "30px",
    whiteSpace: "break-spaces",
  },
});

export default function EmptyStateContent(props) {
  return (
    <ThemeProvider theme={ACTheme}>
      <EmptyStateContentContent {...props} />
    </ThemeProvider>
  );
}

function EmptyStateContentContent(props) {
  const {
    imageArray,
    imageContainerBottomMargin,
    titleText,
    contentText,
    buttonText,
    onButtonClick,
  } = props;
  const classes = useStyles();

  let renderImages = true;
  let renderButton = true;

  if (!buttonText || !onButtonClick) {
    renderButton = false;
  }

  if (!imageArray || imageArray.length < 1) {
    renderImages = false;
  }

  return (
    <div className={classes.container}>
      <div
        className={renderImages ? classes.imageContainer : ""}
        style={
          renderImages && imageContainerBottomMargin
            ? { marginBottom: imageContainerBottomMargin }
            : {}
        }
      >
        {imageArray &&
          imageArray.length > 0 &&
          imageArray.map((image) => {
            return (
              <img
                key={image.src}
                className={classes.image}
                src={image.src}
                alt={image.alt ? image.alt : "missing alt"}
                width={image.width && image.width > 0 ? image.width : "200"}
              />
            );
          })}
      </div>
      <div className={classes.titleAndContent}>
        <TitleAndContent titleText={titleText} contentText={contentText} />
      </div>
      <div className={renderButton ? classes.button : ""}>
        {buttonText && onButtonClick ? (
          <Button
            color="primary"
            text={buttonText}
            type="action"
            onClick={onButtonClick}
            width="200px"
          />
        ) : null}
      </div>
    </div>
  );
}
