import React from "react";
import {
  makeStyles,
  Paper,
  Typography,
  ThemeProvider,
} from "@material-ui/core";
import Button from "../Button";
import { default as ACTheme } from "../../themes";

const useStyles = makeStyles(() => ({
  mask: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 3,
    border: "none",
  },
  container: {
    display: "flex",
    width: 335,
    height: 232,
  },
  modalContent: {
    display: "flex",
    flexDirection: "column",
    flex: "auto",
    paddingTop: 30,
    alignItems: "center",
  },
  title: {
    width: 235,
  },
  boardImage: {
    width: 195,
    height: 46,
    marginTop: 25,
  },
  actionsContainer: {
    marginTop: 25,
    display: "flex",
    flexDirection: "row",
  },
}));

export default function ResignFromAdvisoryBoardModal(props) {
  return (
    <ThemeProvider theme={ACTheme}>
      <ResignFromAdvisoryBoardModalContent {...props} />
    </ThemeProvider>
  );
}

function ResignFromAdvisoryBoardModalContent({
  image,
  onCancel,
  onDismiss,
  onSubmit,
  title,
  cancelButtonText,
  resignButtonText,
  visible,
}) {
  const classes = useStyles();
  if (!visible) {
    return null;
  }

  return (
    <button className={classes.mask} type="submit" onClick={onDismiss}>
      <Paper className={classes.container}>
        <div className={classes.modalContent}>
          <Typography variant="h2" className={classes.title}>
            {title}
          </Typography>
          <img alt="" className={classes.boardImage} src={image} />
          <div className={classes.actionsContainer}>
            <Button
              style={{ borderRadius: 3 }}
              variant="outlined"
              color="primary"
              size="small"
              onClick={onCancel}
            >
              {cancelButtonText}
            </Button>
            <Button
              style={{ borderRadius: 3, marginLeft: 8 }}
              color="primary"
              size="small"
              onClick={onSubmit}
            >
              {resignButtonText}
            </Button>
          </div>
        </div>
      </Paper>
    </button>
  );
}
