import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Typography,
  TextField,
  useMediaQuery,
  ThemeProvider,
} from "@material-ui/core";
import Avatar from "../Avatar";
import Button from "../Button";
import { default as ACTheme } from "../../themes";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  avatarContainer: {
    display: "flex",
    marginRight: 10,
  },
  inputContainer: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-end",
    flex: "auto",
    width: "100%",
    marginTop: "8px",
  },
  input: {
    minHeight: (isSM) => (isSM ? 35 : 43),
    padding: 7,
  },
  firstRowContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  warningText: {
    color: theme.palette.error.main,
  },
}));

export default function Reply(props) {
  return (
    <ThemeProvider theme={ACTheme}>
      <ReplyContent {...props} />
    </ThemeProvider>
  );
}

function ReplyContent(props) {
  const {
    onSubmit,
    image,
    type,
    loading,
    onCancel,
    value,
    variant,
    placeholder,
  } = props;
  const { showCancel, avatarClick, ...filteredProps } = props;
  const isSM = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [IS_INPUT_VALID, setIS_INPUT_VALID] = useState(false);
  const classes = useStyles(isSM);

  function handleButtonClick() {
    if (onSubmit) {
      onSubmit(value);
    }
  }

  function handleCancelClick() {
    onCancel();
  }

  useEffect(() => {
    if (value.length > 0) {
      if (value.trim().length > 29) {
        setIS_INPUT_VALID(true);
      } else {
        setIS_INPUT_VALID(false);
      }
    } else {
      setIS_INPUT_VALID(false);
    }
  }, [value]);

  function renderResponseType() {
    return (
      <React.Fragment>
        {IS_INPUT_VALID ? (
          <React.Fragment>
            {showCancel && (
              <Button
                color="secondary"
                variant="outlined"
                onClick={handleCancelClick}
                disabled={!IS_INPUT_VALID}
                style={{
                  borderRadius: 5,
                  color: "rgb(110,110,119)",
                  marginRight: "7px",
                }}
                size={isSM ? "small" : "medium"}
              >
                <Typography variant="body2">Cancel</Typography>
              </Button>
            )}

            <Button
              color="primary"
              variant={variant}
              onClick={handleButtonClick}
              disabled={!IS_INPUT_VALID}
              style={{ borderRadius: 5 }}
              loading={loading ? 1 : 0}
              size={isSM ? "small" : "medium"}
            >
              <Typography variant="body2">
                {type === "reply" ? "Reply" : "Post"}
              </Typography>
            </Button>
          </React.Fragment>
        ) : (
          <Typography className={classes.warningText} variant="caption">
            {value === undefined || value === null || value.length === 0
              ? null
              : " A minimum of 30 characters are required."}
          </Typography>
        )}
      </React.Fragment>
    );
  }

  return (
    <div className={classes.container}>
      <div className={classes.firstRowContainer}>
        <div className={classes.avatarContainer}>
          <Avatar
            image={image}
            size={isSM ? "sm" : "md"}
            avatarClick={avatarClick}
          />
        </div>
        <TextField
          {...filteredProps}
          fullWidth
          multiline
          placeholder={placeholder ? placeholder : "Add a comment..."}
          InputProps={{
            classes: {
              root: classes.input,

              notchedOutline: classes.notchedOutline,
            },
          }}
          variant="outlined"
        />
      </div>
      <div className={classes.inputContainer}>{renderResponseType()}</div>
    </div>
  );
}
