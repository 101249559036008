import React, { useState, useRef, useEffect } from "react";
import {
  makeStyles,
  useMediaQuery,
  IconButton,
  ThemeProvider,
} from "@material-ui/core";
import ArrowBackIosOutlinedIcon from "@material-ui/icons/ArrowBackIosOutlined";
import ArrowForwardIosOutlinedIcon from "@material-ui/icons/ArrowForwardIosOutlined";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { default as ACTheme } from "../../themes";

const useStyles = makeStyles((theme) => ({
  carouselSlideContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    height: "100%",
  },

  carouselSlideComponent: {
    height: "100%",
    overflow: "hidden",
    width: "90%",
    transition: "all 0.5s ease-in-out",
    position: "relative",
    display: "flex",
  },
  carouselSlideFade: {
    width: "15px",
    background:
      "linear-gradient(270deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.4962359943977591) 50%, rgba(255,255,255,0) 100%)",
    position: "absolute",
    height: "100%",
    right: 0,
    zIndex: 999,
  },

  slides: {
    display: "flex",
    flexDirection: "row",
    alignContent: "flex-start",
    height: "100%",
    width: "100%",
  },

  buttonLeft: {
    border: `1px solid ${theme.palette.secondary.main}`,
    zIndex: 999,
    marginRight: "5px",
  },

  buttonRight: {
    border: `1px solid ${theme.palette.secondary.main}`,
    zIndex: 999,
  },

  deleteButton: {
    zIndex: 999,
    position: "absolute",
    top: 2,
    right: 2,
    padding: 0,
    margin: 0,
    display: "flex",
    width: "18px",
    height: "18px",
    borderRadius: "50%",
    overflow: "hidden",
  },

  slideRightArrow: {
    paddingLeft: "2px",
  },

  slideLeftArrow: {
    paddingRight: "2px",
  },

  deleteIcon: {
    fontSize: 22,
    backgroundColor: "white",
    borderRadius: "50%",
    padding: 0,
    margin: 0,
  },

  itemsContainer: {
    display: "flex",
    justifyContent: "flex-start",
    transform:
      "translateX(calc( (var(--offset) * ((var(--elementWidth) * -1px) + -15px))))",
    transition: "transform 1s ease-out",
    listStyleType: "none",
    paddingLeft: 0,
  },
  slide: {
    border: "none",
    marginRight: "15px",
    ariaHidden: true,
    position: "relative",
  },

  slideSelected: {
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: "5px",
    marginRight: "20px",
    ariaHidden: true,
    overflow: "hidden",
    zIndex: 999,
    position: "relative",
    "-webkit-animation": "fadeOut 500ms",
    "-moz-animation": "fadeOut 500ms",
    animation: "fadeOut 500ms",
  },
  innerButton: {
    outline: "none",
    border: "none",
    background: "none",
    cursor: "pointer",
    "& :focus": {
      outline: "none",
    },
    padding: 0,
    margin: 0,
    display: "flex",
    width: "fit-content",
    height: "fit-content",
  },
}));

export default function GenericCarousel(props) {
  return (
    <ThemeProvider theme={ACTheme}>
      <GenericCarouselContent {...props} />
    </ThemeProvider>
  );
}

function GenericCarouselContent(props) {
  const classes = useStyles();
  const { children, deleteElement, slideSelected } = props;
  const listRef = useRef();
  const [slideIndex, setSlideIndex] = useState(0);
  const isSM = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const childWidth = children[0].props.width;

  function prevSlide() {
    if (slideIndex === 0) {
      setSlideIndex(0);
    } else {
      setSlideIndex(slideIndex - 1);
      slideSelected(slideIndex - 1);
    }
  }

  function nextSlide() {
    if (slideIndex === children.length - 1) {
      setSlideIndex(slideIndex);
      slideSelected(slideIndex);
    } else {
      setSlideIndex(slideIndex + 1);
      slideSelected(slideIndex + 1);
    }
  }

  function slideStartMoment() {
    if (!isSM && slideIndex === 3) {
      return 1;
    }
    if (!isSM && slideIndex > 3) {
      return slideIndex - 2;
    }
    if (!isSM && slideIndex < 3) {
      return 0;
    }

    return slideIndex;
  }

  function selectItem(indexValue) {
    setSlideIndex(indexValue);
  }

  function isWidth() {
    if (childWidth === null || childWidth === undefined) {
      return "105";
    }
    return childWidth;
  }

  function deleteSlideLogic(data) {
    if (data.originIndex === slideIndex && data.originIndex !== 0) {
      deleteElement(data.key);
      slideSelected(data.originIndex - 1);
      setSlideIndex(data.originIndex - 1);
    } else if (
      data.originIndex !== slideIndex &&
      data.originIndex === 0 &&
      children.length !== 1
    ) {
      deleteElement(data.key);
      slideSelected(slideIndex);
      setSlideIndex(slideIndex - 1);
    } else if (
      data.originIndex === slideIndex &&
      data.originIndex === 0 &&
      children.length === 1
    ) {
      deleteElement(data.key);
      slideSelected(-1);
      setSlideIndex();
    } else {
      deleteElement(data.key);
    }
  }

  useEffect(() => {
    slideSelected(0);
  }, []);

  return (
    <div className={classes.carouselSlideContainer} id="carousel">
      {children.length > 3 ? (
        <IconButton
          color={slideIndex === 0 ? "secondary" : "primary"}
          disabled={slideIndex === 0}
          className={classes.buttonLeft}
          onClick={() => prevSlide()}
          component="span"
          size="small"
        >
          <ArrowBackIosOutlinedIcon
            className={classes.slideLeftArrow}
            fontSize="small"
          />
        </IconButton>
      ) : null}

      <div className={classes.carouselSlideComponent}>
        <ul
          className={classes.itemsContainer}
          ref={listRef}
          style={{
            "--offset": slideStartMoment(),
            "--elementWidth": isWidth(),
          }}
        >
          {children.map((obj, index) => {
            const selected = index === slideIndex;
            const cloneElement = React.cloneElement(obj, {
              selected,
            });

            return (
              <li
                key={children[index].props.img}
                className={selected ? classes.slideSelected : classes.slide}
              >
                <button
                  onClick={() => {
                    selectItem(index);
                    slideSelected(index);
                  }}
                  type="button"
                  className={classes.innerButton}
                >
                  {cloneElement}
                </button>
                {deleteElement ? (
                  <IconButton
                    color="primary"
                    component="span"
                    className={classes.deleteButton}
                    onClick={() => {
                      deleteSlideLogic({
                        originIndex: index,
                        key: children[index].key,
                      });
                    }}
                    size="small"
                  >
                    <HighlightOffIcon className={classes.deleteIcon} />
                  </IconButton>
                ) : null}
              </li>
            );
          })}
        </ul>
        <div className={classes.carouselSlideFade} />
      </div>
      {children.length > 3 ? (
        <IconButton
          color={slideIndex === children.length - 1 ? "secondary" : "primary"}
          component="span"
          disabled={slideIndex === children.length - 1}
          className={classes.buttonRight}
          onClick={() => nextSlide()}
          size="small"
        >
          <ArrowForwardIosOutlinedIcon
            className={classes.slideRightArrow}
            fontSize="small"
          />
        </IconButton>
      ) : null}
    </div>
  );
}
