import React from "react";
import {
  makeStyles,
  useMediaQuery,
  Paper,
  Typography,
  ThemeProvider,
} from "@material-ui/core";
import AdminUserCardHeader from "./AdminUserHeader";
import Button from "../Button";
import ACIcon from "../ACIcon";
import { default as ACTheme } from "../../themes";

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: theme.palette.common.white,
    width: "100%",
    borderRadius: 4,
    display: "flex",
    flexDirection: "column",
    height: "fit-content",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    borderLeft: "5px solid rgb(0, 104, 173)",
    position: "relative",
  },
  borderContainer: {
    border: "1px solid rgb(230,237,254)",
    padding: 7,
    position: "relative",
    width: "100%",
    height: "100%",
  },

  separator: {
    width: "100%",
    marginTop: 0,
  },
  separatorLine: {
    width: "100%",
    borderBottom: "1px solid rgb(230,237,254)",
    bottom: 70,
    left: 0,
    position: "absolute",
  },
  postCardBottom: {
    paddingTop: "30px",
    paddingBottom: "15px",
    display: "flex",
    justifyContent: "space-between",
  },
  badge: {
    position: "absolute",
    top: 0,
    left: -2,
    zIndex: 99,
  },
  badgeText: {
    paddingLeft: 5,
    paddingRight: 5,
    borderRadius: "0% 0% 10% 0% / 0% 0% 18% 0%",
    fontSize: "0.6rem",
    color: "white",
    backgroundColor: "rgb(0, 104, 173)",
    textTransform: "uppercase",
  },
  leftButtonsContainer: {
    width: "fit-content",
    display: "flex",
  },
  rightButtonsContainer: {
    width: "fit-content",
    display: "flex",
  },
}));

export default function AdminUserCard(props) {
  return (
    <ThemeProvider theme={ACTheme}>
      <AdminUserCardContent {...props} />
    </ThemeProvider>
  );
}

const AdminUserCardContent = (props) => {
  const {
    // Header props
    status,
    firstName,
    lastName,
    userTitle,
    companyName,
    phoneNumber,
    email,
    accountLevel,
    lastLoginDate,
    registrationDate,
    // FooterProps
    cardLeftButtons,
    cardRightButtons,
    //format
    headerFormat,
    avatarImage,
    boardOwnerName,
    boardType,
    seatsCount,
    boardTotalSeats,
    planCode,
    advisorNumberOfBoards,
    isFreeTrial,
    editUserInfoDisabled,
  } = props;
  const isSM = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles(isSM);

  return (
    <Paper elevation={0} className={classes.card}>
      <div className={classes.badge}>
        <Typography className={classes.badgeText}>{accountLevel}</Typography>
      </div>
      <div className={classes.borderContainer}>
        <div className={classes.separator}>
          <AdminUserCardHeader
            status={status}
            firstName={firstName}
            lastName={lastName}
            userTitle={userTitle}
            companyName={companyName}
            phoneNumber={phoneNumber}
            email={email}
            accountLevel={accountLevel}
            lastLoginDate={lastLoginDate}
            registrationDate={registrationDate}
            headerFormat={headerFormat}
            avatarImage={avatarImage}
            boardOwnerName={boardOwnerName}
            boardType={boardType}
            seatsCount={seatsCount}
            boardTotalSeats={boardTotalSeats}
            planCode={planCode}
            advisorNumberOfBoards={advisorNumberOfBoards}
            isFreeTrial={isFreeTrial}
            editUserInfoDisabled={editUserInfoDisabled}
          />
        </div>

        <div className={classes.separatorLine} />

        <div className={classes.postCardBottom}>
          <div className={classes.leftButtonsContainer}>
            {cardLeftButtons.map((element, index) => (
              <div
                key={element.id}
                style={{
                  marginLeft: index === 0 ? 0 : "10px",
                }}
              >
                <Button
                  color="primary"
                  width="95px"
                  style={{ fontSize: ".7rem" }}
                  onClick={element.onClick}
                  disabled={element.disabled}
                >
                  {element.text}
                </Button>
              </div>
            ))}
          </div>
          <div className={classes.rightButtonsContainer}>
            {cardRightButtons.map((element, index) => (
              <div
                key={element.id}
                style={{
                  marginLeft: index === 0 ? 0 : "10px",
                }}
              >
                <Button
                  color="primary"
                  width="fit-content"
                  style={{
                    fontSize: ".7rem",
                  }}
                  onClick={element.onClick}
                  disabled={element.disabled}
                  variant="outlined"
                >
                  <ACIcon
                    iconname={element.iconname}
                    style={{
                      fontSize: "1rem",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "fit-content",
                      height: "fit-content",
                      marginBottom: 2,
                    }}
                  />
                  {element.text}
                </Button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Paper>
  );
};
