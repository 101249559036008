import React from "react";
import { makeStyles, Box, ThemeProvider } from "@material-ui/core";
import ACIcon from "../ACIcon";
import { ReactComponent as Ring } from "./load_ring.svg";
import { default as ACTheme } from "../../themes";

const useStyles = makeStyles((theme) => ({
  box: (props) => ({
    width: props.size,
    height: props.size,
    minWidth: "30px",
    minHeight: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  }),
  logo: {
    borderRadius: "100%",
    width: "69%",
    height: "69%",
    position: "absolute",
  },
  ring: {
    borderRadius: "100%",
    width: "100%",
    height: "100%",
    position: "relative",
    animationName: "$rotation",
    animationDuration: "1s",
    animationTimingFunction: "linear",
    animationIterationCount: "infinite",
  },
  "@keyframes rotation": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
}));

export default function LoadingIndicatorLogo(props) {
  return (
    <ThemeProvider theme={ACTheme}>
      <LoadingIndicatorLogoContent {...props} />
    </ThemeProvider>
  );
}

function LoadingIndicatorLogoContent({ size, iconFontSize, iconRight }) {
  const props = {
    size: `${size}px`,
  };
  const classes = useStyles(props);
  return (
    <Box className={classes.box}>
      <Ring className={classes.ring} viewBox="0 0 400 400" />
      <ACIcon
        iconname="cloud-logo"
        style={{
          color: "#0F78FD",
          fontSize: iconFontSize,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "17px",
          position: "absolute",
          right: iconRight,
        }}
      />
    </Box>
  );
}

LoadingIndicatorLogo.defaultProps = {
  size: "300",
};
