import React, { useEffect } from "react";
import { makeStyles, ThemeProvider } from "@material-ui/core";
import { default as ACTheme } from "../../themes";

const useStyles = makeStyles(() => ({
  container: {
    bottom: 0,
    display: "flex",
    left: 0,
    position: "absolute",
    right: 0,
    top: 0,
    zIndex: 1,
  },
}));

export default function MinimumDisplayTime(props) {
  return (
    <ThemeProvider theme={ACTheme}>
      <MinimumDisplayTimeContent {...props} />
    </ThemeProvider>
  );
}

function MinimumDisplayTimeContent({
  children,
  displayTime = 1000,
  visible,
  onHide,
}) {
  const classes = useStyles();

  function timeOut() {
    setTimeout(() => {
      if (onHide) {
        onHide();
      }
    }, displayTime);
  }

  useEffect(() => {
    if (visible) {
      timeOut();
    }
    return () => {
      clearTimeout(timeOut);
    };
  }, []);

  return (
    <div>{visible && <div className={classes.container}>{children}</div>}</div>
  );
}
