import React from "react";
import { makeStyles, Typography, useMediaQuery } from "@material-ui/core";
import Avatar from "../../Avatar";
import AdvisorDetailVerticalElipsis from "./RightHandVerticalElipsies";
import Button from "../../Button";
import ProfileHighlighterPill from "../../ProfileHighlighterPill";
import ACIcon from "../../ACIcon";

const useStyles = makeStyles((theme) => ({
  answerHeaderContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },
  answerHeaderSubContainer: {
    display: "flex",
    width: "100%",
  },
  answerAvatarContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  advisorInfoContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    position: "relative",
    marginLeft: (isSM) => (isSM ? "7px" : "15px"),
  },
  advisorSubtitle: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  topButtonsContainer: {
    display: "flex",
    flexShrink: 0,
    marginBottom: "auto",
  },
  advisorSkillsContainer: {
    marginTop: (isSM) => (isSM ? "10px" : "0px"),
    marginLeft: (isSM) => (isSM ? "0px" : "15px"),
  },
  advisorName: {
    display: "flex",
    flexShrink: 0,
  },
  advisorNameText: {
    fontSize: (isSM) => (isSM ? "0.7rem" : "0.8rem"),
    fontWeight: 600,
    color: theme.palette.info.dark,
    marginRight: 10,
    marginTop: (skillsAboveText) => (skillsAboveText ? 10 : 0),
  },
  advisorNameTextTop: {
    fontSize: (isSM) => (isSM ? "0.7rem" : "0.8rem"),
    fontWeight: 600,
    color: theme.palette.info.dark,
    marginRight: (isSM) => (isSM ? "0px" : "10px"),
    width: "fit-content",
    display: "flex",
    wordWrap: "break-word",
  },
  profileHighlighter: {
    display: "flex",
    marginLeft: (isSM) => (isSM ? "4px" : "0px"),
  },
  advisorSubtitleText: {
    color: theme.palette.secondary.dark,
    fontSize: (isSM) => (isSM ? "0.7rem" : "0.8rem"),
    width: "70px",
  },
  skills: {
    display: "flex",
    color: theme.palette.secondary.dark,
    fontSize: (isSM) => (isSM ? "0.7rem" : "0.8rem"),
    overflowWrap: "normal",
    marginTop: 10,
  },
  requestMeetingText: {
    fontSize: (isSM) => (isSM ? "0.69rem" : "0.75rem"),
  },
  buttonSeparator: {
    marginLeft: (isSM) => (isSM ? "5px" : "15px"),
  },
  dotSeparator: {
    fontSize: ".5rem",
    marginRight: (isSM) => (isSM ? "3px" : "5px"),
  },
  flexContainer: {
    display: "flex",
  },
  advisorStatusText: {
    lineHeight: "2",
    color: theme.palette.info.light,
    marginRight: "10px",
  },
  advisorStatusIcon: {
    color: theme.palette.info.light,
    marginRight: "7px",
    width: (isSM) => (isSM ? "15px" : "20px"),
    height: (isSM) => (isSM ? "15px" : "20px"),
    justifyContent: "center",
    alignItems: "center",
    display: "inline",
  },
  advisorStatusContainer: {
    display: "flex",
    alignItems: "center",
  },
  formerBoardMemberLabel: {
    lineHeight: "0",
    color: theme.palette.info.light,
  },
  formerBoardMemberTextContainer: {
    minWidth: (isXS) => (isXS ? "90px" : "250px"),
    textAlign: "center",
  },
  remove: {
    display: "none",
  },
}));

function AnswerCardHeader(props) {
  const {
    requestMeetingClick,
    requestMeetingAlternateText,
    reportAdvisorClick,
    advisorName,
    advisorAvatar,
    advisorCompany,
    advisorInfo,
    skillsAboveText,
    skills,
    invitedToJoinDate,
    formerBoardMember,
    isStar,
    isStarFilled,
    starClicked,
    profileHighlighter,
    profileHighlighterType,
    profileHighlighterDate,
    profileHighlighterAlternateSettings,
    ProfileHighlighterCustomIcon,
    meetingHighlighter,
    meetingHighlighterType,
    MeetingHighlighterCustomIcon,
    isVerticalElipsis,
    buttonTitles,
    addtopclasses,
    boardMeetingStaticClasses,
  } = props;
  const isSM = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isXS = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const classes = useStyles(isSM, isXS, skillsAboveText);

  const setDate = new Date(invitedToJoinDate);
  const month = setDate.getMonth() + 1;
  const day = setDate.getDate();
  const year = setDate.getFullYear();

  const reformatDate = () => {
    let formatMonth = "";
    let formatDay = "";

    if (String(day).length < 2) {
      formatDay = "0" + day;
    } else {
      formatDay = day;
    }

    if (String(month).length < 2) {
      formatMonth = "0" + month;
    } else {
      formatMonth = month;
    }

    return formatMonth + "/" + formatDay + "/" + year;
  };

  function avatarSize() {
    if (isXS) {
      return "md";
    }
    if (isSM) {
      return "lg-mobile";
    }
    return "xl";
  }

  function buttonHeight() {
    if (isXS) {
      return 35;
    }
    if (isSM) {
      return 23;
    }
    if (requestMeetingAlternateText) {
      return 33;
    }
    return 25;
  }

  function renderOpportunityButton() {
    let button = !buttonTitles ? (
      <div
        className={
          boardMeetingStaticClasses ? " boardMeetingOppViewButton" : ""
        }
        style={{ display: "flex" }}
      >
        <Button
          style={{
            borderRadius: "4px 4px 4px 4px",
            height: buttonHeight(),
            marginTop: isSM ? "0px" : "2px",
            minWidth: requestMeetingAlternateText ? "140px" : "",
          }}
          color="primary"
          size="small"
          width={requestMeetingButtonWidth()}
          onClick={(event) => {
            requestMeetingClick();
            event.stopPropagation();
          }}
        >
          <Typography variant="caption" className={classes.requestMeetingText}>
            {requestMeetingAlternateText || "Request Meeting"}
          </Typography>
        </Button>
      </div>
    ) : (
      <Button
        style={{
          borderRadius: "4px 4px 4px 4px",
          height: buttonHeight(),
          marginTop: isSM ? "0px" : "2px",
        }}
        color="primary"
        size="small"
        width={actionButtonWidth()}
        isAction={true}
        buttonTitles={buttonTitles}
      >
        Actions
      </Button>
    );

    return isXS ? null : button;
  }

  function actionButtonWidth() {
    if (isSM) {
      return 80;
    }
    return 100;
  }

  function requestMeetingButtonWidth() {
    if (isXS) {
      return 80;
    }
    if (isSM) {
      return 120;
    }
    return 125;
  }

  function displayInvitedToJoinDate() {
    return invitedToJoinDate && !profileHighlighter && !formerBoardMember;
  }

  function displayHighlighter() {
    return profileHighlighter && !formerBoardMember;
  }

  const string = skills.toString();
  const replaced = string.replaceAll(",", " \u2022 ");

  return (
    <div className={classes.answerHeaderContainer}>
      <div className={classes.answerHeaderSubContainer}>
        <div className={classes.answerAvatarContainer}>
          <Avatar
            image={advisorAvatar}
            size={avatarSize()}
            isStar={isStar}
            isStarFilled={isStarFilled}
            onClick={starClicked}
          />
        </div>
        <div style={{ width: "100%" }}>
          <div className={classes.flexContainer}>
            <div className={classes.advisorInfoContainer}>
              <div className={classes.advisorName}>
                <Typography
                  variant="body2"
                  className={classes.advisorNameTextTop}
                >
                  {advisorName}
                </Typography>
                {!isSM ? (
                  <div className={classes.profileHighlighter}>
                    {displayHighlighter() ? (
                      <ProfileHighlighterPill
                        date={profileHighlighterDate}
                        type={profileHighlighterType}
                        alternateSettings={profileHighlighterAlternateSettings}
                        CustomIcon={ProfileHighlighterCustomIcon}
                      />
                    ) : null}
                  </div>
                ) : null}
                {!isSM && meetingHighlighter ? (
                  <div
                    className={`${classes.profileHighlighter}${
                      boardMeetingStaticClasses ? " boardMeetingBadge" : ""
                    }`}
                  >
                    <ProfileHighlighterPill
                      isMeeting
                      type={meetingHighlighterType}
                      alternateSettings={profileHighlighterAlternateSettings}
                      CustomIcon={MeetingHighlighterCustomIcon}
                    />
                  </div>
                ) : null}
              </div>
              <div className={classes.advisorSubtitle}>
                <Typography
                  variant="caption"
                  className={classes.advisorSubtitleText}
                  style={{ width: "100%" }}
                >
                  {advisorInfo}
                </Typography>
              </div>
              <div className={classes.advisorSubtitle}>
                <Typography
                  variant="caption"
                  className={classes.advisorSubtitleText}
                  style={{ width: "100%" }}
                >
                  {advisorCompany}
                </Typography>
              </div>
            </div>
            <div
              className={
                formerBoardMember ? classes.remove : classes.topButtonsContainer
              }
            >
              <div
                className={
                  displayInvitedToJoinDate() && !isSM
                    ? classes.advisorStatusContainer
                    : classes.remove
                }
              >
                <ACIcon
                  className={classes.advisorStatusIcon}
                  iconname={"add-outlined"}
                />
                <Typography
                  className={classes.advisorStatusText}
                  variant="body1"
                >
                  Invited on {reformatDate()}
                </Typography>
              </div>

              {renderOpportunityButton()}
              <div className={classes.buttonSeparator}>
                {isVerticalElipsis && (
                  <div className={classes.buttonSeparator}>
                    <AdvisorDetailVerticalElipsis
                      reportAdvisorClick={reportAdvisorClick}
                    />
                  </div>
                )}
              </div>
            </div>
            <div
              className={
                formerBoardMember
                  ? classes.formerBoardMemberTextContainer
                  : classes.remove
              }
            >
              <Typography
                className={classes.formerBoardMemberLabel}
                variant={isSM ? "caption" : "body1"}
              >
                Former Board Member
              </Typography>
            </div>
          </div>
          {displayInvitedToJoinDate() && isSM ? (
            <div
              className={classes.advisorStatusContainer}
              style={{ marginLeft: "5px" }}
            >
              <ACIcon
                className={classes.advisorStatusIcon}
                iconname={"add-outlined"}
              />
              <Typography
                className={classes.advisorStatusText}
                variant="caption"
              >
                Invited on {reformatDate()}
              </Typography>
            </div>
          ) : null}
          {isSM ? (
            <div className={classes.profileHighlighter}>
              {displayHighlighter() ? (
                <ProfileHighlighterPill
                  date={profileHighlighterDate}
                  type={profileHighlighterType}
                />
              ) : null}
            </div>
          ) : null}
          {isSM && meetingHighlighter ? (
            <div className={classes.profileHighlighter}>
              <ProfileHighlighterPill
                isMeeting
                type={meetingHighlighterType}
                alternateSettings={profileHighlighterAlternateSettings}
                CustomIcon={MeetingHighlighterCustomIcon}
              />
            </div>
          ) : null}
          {!isSM ? (
            <div className={classes.advisorSkillsContainer}>
              {skillsAboveText ? (
                <Typography variant="caption" className={classes.skills}>
                  {skillsAboveText}
                </Typography>
              ) : null}
              <Typography className={classes.advisorNameText}>
                {replaced}
              </Typography>
            </div>
          ) : null}
        </div>
      </div>
      {isSM ? (
        <div className={classes.advisorSkillsContainer}>
          {skillsAboveText ? (
            <Typography variant="caption" className={classes.skills}>
              {skillsAboveText}
            </Typography>
          ) : null}
          <Typography className={classes.advisorNameText}>
            {replaced}
          </Typography>
        </div>
      ) : null}
    </div>
  );
}

export default AnswerCardHeader;
