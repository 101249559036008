import React from "react";
import { makeStyles, Typography, useMediaQuery, Link } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    width: "100%",
    height: "fit-content",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    flexDirection: "column",
    marginBottom: "10px",
  },
  bodyIndividual: {
    width: "100%",
    flexGrow: 1,
    flexShrink: 1,
    overflowWrap: "break-word",
    wordBreak: "break-word",
    display: "inline-block",
    height: "fit-content",
    color: theme.palette.info.dark,
    backgroundColor: "#ffff",
    fontSize: (isSM) => (isSM ? "0.7rem" : "0.8rem"),
  },
  bio: {
    display: "flex",
    color: theme.palette.secondary.dark,
    fontSize: (isSM) => (isSM ? "0.7rem" : "0.8rem"),
  },
}));

function AnswerBody(props) {
  const {
    alternateLinkText,
    bio,
    bioAlternateTitle,
    bioLinkTarget,
    bioLinkOpenBlank,
    bioMaxLength,
    bioShowLink,
    bioLinkAsClickAction,
    bioLinkClickAction,
  } = props;
  const isSM = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles(isSM);

  const trimValue = bioMaxLength || 208;
  const trimmedBio =
    bio.length > trimValue ? bio.substring(0, trimValue) + "..." : bio;

  function getLink() {
    if (bioLinkAsClickAction) {
      return (
        <Link onClick={bioLinkClickAction} style={{ cursor: "pointer" }}>
          {alternateLinkText || "Learn More"}
        </Link>
      );
    }
    return (
      <Link href={bioLinkTarget} target={bioLinkOpenBlank ? "_blank" : ""}>
        {alternateLinkText || "Learn More"}
      </Link>
    );
  }

  return (
    <React.Fragment>
      <div className={classes.mainContainer}>
        <div className={classes.timeContainer}>
          <Typography variant="caption" className={classes.bio}>
            {bioAlternateTitle || "BIOGRAPHY"}
          </Typography>
        </div>
        <Typography
          id="biographyParagraph"
          className={classes.bodyIndividual}
          align="left"
        >
          <React.Fragment>
            <span>{trimmedBio}</span>
            {bioShowLink ? getLink() : null}
          </React.Fragment>
        </Typography>
      </div>
    </React.Fragment>
  );
}

export default AnswerBody;
