import React from "react";
import { makeStyles, Typography, ThemeProvider } from "@material-ui/core";
import { CloseOutlined } from "@material-ui/icons";
import Avatar from "../Avatar";
import { default as ACTheme } from "../../themes";

const useStyles = makeStyles((theme) => ({
  card: {
    minWidth: 184,
    display: "flex",
    flexDirection: "row",
    background: theme.palette.common.white,
  },
  closeButton: {
    alignItems: "center",
    border: "solid",
    borderColor: theme.palette.primary.main,
    borderRadius: 18,
    borderWidth: 1,
    display: "flex",
    height: 18,
    justifyContent: "center",
    padding: 0,
    width: 18,
    position: "relative",
    zIndex: 1,
    marginLeft: "auto",
  },
  closeIcon: {
    fontSize: 15,
  },
  detailsContainer: {
    display: "flex",
    flex: "auto",
    flexDirection: "column",
    justifyContent: "center",
    paddingLeft: 10,
  },
}));

export default function CompanyMember(props) {
  return (
    <ThemeProvider theme={ACTheme}>
      <CompanyMemberContent {...props} />
    </ThemeProvider>
  );
}

function CompanyMemberContent({ image, name, title, onClose }) {
  const classes = useStyles();

  return (
    <div className={classes.card}>
      <Avatar image={image} size="md" />
      <div className={classes.detailsContainer}>
        <Typography variant="body2">{name}</Typography>
        <Typography variant="body1">{title}</Typography>
      </div>
      <a className={classes.closeButton} onClick={onClose}>
        <CloseOutlined className={classes.closeIcon} color="primary" />
      </a>
    </div>
  );
}
