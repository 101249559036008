import React from "react";
import { makeStyles, Typography, ThemeProvider } from "@material-ui/core";
import { default as ACTheme } from "../../themes";

const useStyles = makeStyles((theme) => ({
  highlightContainer: {
    display: "flex",
    flexDirection: "row",
  },
  imgContainer: {
    margin: 0,
    padding: 0,
  },
  infoContainer: {
    alignContent: "center",
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    marginTop: "10px",
  },
  counter: {
    fontFamily: "Open Sans",
    fontWeight: "bold",
    fontSize: "22px",
  },
  title: {
    fontFamily: "Open Sans",
    fontWeight: "bold",
    fontSize: "9px",
    color: theme.palette.grey[400],
  },
}));

export default function AdvisorMenuHighlight(props) {
  return (
    <ThemeProvider theme={ACTheme}>
      <AdvisorMenuHighlightContent {...props} />
    </ThemeProvider>
  );
}

function AdvisorMenuHighlightContent(props) {
  const { imageSrc, imageAlt, title, counter } = props;
  const classes = useStyles();

  return (
    <div className={classes.highlightContainer}>
      <div className={classes.imgContainer}>
        <img alt={imageAlt} src={imageSrc} height="60" width="60" />
      </div>
      <div className={classes.infoContainer}>
        <Typography className={classes.counter}>{counter}</Typography>
        <Typography className={classes.title}>{title}</Typography>
      </div>
    </div>
  );
}
