import React from "react";
import { makeStyles, Divider, ThemeProvider } from "@material-ui/core";
import Dropdown from "../Dropdown";
import { default as ACTheme } from "../../themes";

const useStyles = makeStyles((theme) => ({
  divider: {
    width: "100%",
    color: theme.palette.info.light,
    position: "relative",
    top: "50%",
  },
  sortDividerflexContainer: {
    display: "flex",
    height: "40px",
    marginBottom: "25px",
  },
  dividerContainer: {
    width: "100%",
  },
  sortDropdownContainer: {
    position: "relative",
    top: "-20px",
  },
}));

export default function PostFeedDivider(props) {
  return (
    <ThemeProvider theme={ACTheme}>
      <PostFeedDividerContent {...props} />
    </ThemeProvider>
  );
}

function PostFeedDividerContent(props) {
  const { dropdownLabel, dropDownItemList } = props;
  const classes = useStyles();

  return (
    <div className={classes.sortDividerflexContainer}>
      <div className={classes.dividerContainer}>
        <Divider className={classes.divider} />
      </div>
      <div className={classes.sortDropdownContainer}>
        <Dropdown label={dropdownLabel} itemList={dropDownItemList} />
      </div>
    </div>
  );
}
