import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  InputLabel,
  makeStyles,
  OutlinedInput,
  ThemeProvider,
} from "@material-ui/core";
import { VisibilityOutlined, VisibilityOffOutlined } from "@material-ui/icons";
import { default as ACTheme } from "../../themes";

const useStyles = makeStyles((theme) => {
  return {
    label: {
      color: theme.palette.text.secondary,
    },
    passwordComponentContainer: {
      display: "flex",
      flexDirection: "column",
    },
    passwordField: {
      marginTop: 10,
      width: (props) => props.width,
      minWidth: "200px",
    },
    passwordFieldContainer: {
      alignItems: "center",
      display: "flex",
    },
    visibilityIcon: {
      color: theme.palette.grey["500"],
      cursor: "pointer",
    },
  };
});

export default function PasswordField(props) {
  return (
    <ThemeProvider theme={ACTheme}>
      <PasswordFieldContent {...props} />
    </ThemeProvider>
  );
}

function PasswordFieldContent(props) {
  const { id, onMount, onVisibilityToggle, label, autoFocus } = props;
  const filteredProps = { ...props };
  delete filteredProps.onMount;
  delete filteredProps.onVisibilityToggle;
  delete filteredProps.label;
  const [isVisible, setIsVisible] = useState(false);
  const classes = useStyles(props);
  const UUID = useMemo(() => new Date().getTime(), []);

  const handleReference = useCallback((ref) => {
    if (onMount) {
      const data = { ref };

      if (id) {
        data.id = id;
      }

      onMount(data);
    }
  }, []);

  function handleVisibility() {
    setIsVisible(!isVisible);
  }

  useEffect(() => {
    if (onVisibilityToggle) {
      const data = { isVisible };

      if (id) {
        data.id = id;
      }

      onVisibilityToggle(data);
    }
  }, [isVisible]);

  function renderVisibleIcon() {
    if (isVisible) {
      return (
        <VisibilityOffOutlined
          className={classes.visibilityIcon}
          onClick={handleVisibility}
        />
      );
    }

    return (
      <VisibilityOutlined
        className={classes.visibilityIcon}
        onClick={handleVisibility}
      />
    );
  }

  return (
    <div className={classes.passwordComponentContainer}>
      <InputLabel htmlFor={id} id={UUID}>
        {label}
      </InputLabel>

      <div aria-labelledby={UUID} className={classes.passwordFieldContainer}>
        <OutlinedInput
          ref={handleReference}
          autoFocus
          {...filteredProps}
          className={classes.passwordField}
          type={isVisible ? "text" : "password"}
          endAdornment={renderVisibleIcon()}
        />
      </div>
    </div>
  );
}
