import React from "react";
import { makeStyles, useMediaQuery } from "@material-ui/core";
import PostHeaderAvatar from "../../PostHeaderAvatar";
import MoreMenu from "../MoreMenu";

const useStyles = makeStyles({
  answerHeaderContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  rightGroup: {
    display: "flex",
    width: "fit-content",
    height: "fit-content",
    justifyContent: "center",
    alignItems: "center",
  },
  iconContainer: {
    marginRight: "5px",
  },
  answerAvatarContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  timeContainer: {
    position: "absolute",
    left: 12,
    bottom: -25,
  },
});

function AnswerCardHeader({
  requestMeetingButton,
  headerName,
  answerAvatar,
  subtitle,
  isOwner,
  onEditClick,
  isStar,
  isStarFilled,
  starClicked,
  ProfileHighlighterType,
  avatarClick,
}) {
  const classes = useStyles();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <div className={classes.answerHeaderContainer}>
      <div className={classes.answerAvatarContainer}>
        <PostHeaderAvatar
          titleName={headerName}
          image={answerAvatar}
          subtitle={subtitle}
          fontSize={isSM ? "xs" : "sm"}
          avatarSize={isSM ? "sm" : "compact"}
          isStar={isStar}
          isStarFilled={isStarFilled}
          starClicked={starClicked}
          ProfileHighlighterType={ProfileHighlighterType}
          avatarClick={avatarClick}
        />
      </div>
      {requestMeetingButton ? (
        <div className={classes.rightGroup}>Request Meeting Button</div>
      ) : null}
      {isOwner && (
        <MoreMenu editClick={onEditClick} deleteClick={console.log} />
      )}
    </div>
  );
}

export default AnswerCardHeader;
