import React, { useState } from "react";
import {
  Menu,
  MenuItem,
  Fade,
  Backdrop,
  makeStyles,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { fade } from "@material-ui/core/styles/colorManipulator";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ACIcon from "../../../ACIcon";

const useStyles = makeStyles((theme) => ({
  backDrop: {
    zIndex: 999,
    backgroundColor: fade("#000000", 0.05),
  },
  elipsies: {
    color: theme.palette.secondary.main,
    position: "relative",
    fontSize: (isSM) => (isSM ? 20 : 25),
  },
  button: {
    padding: 0,
    margin: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    outline: "none",
    backgroundColor: "transparent",
    cursor: "pointer",
    border: "none",
    width: 10,
    height: 22,
    overflow: "hidden",
  },
  iconStyle: {
    fontSize: 15,
    marginBottom: "2px",
    marginRight: "5px",
  },
  menuItem: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  menuItemText: {
    fontWeight: 600,
  },
  menu: {
    height: "200px",
  },
}));

function VerticalElipsiesMenu(props) {
  const { reportAdvisorClick } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const isSM = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles(isSM);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const Default = () => {
    return (
      <React.Fragment>
        <MenuItem onClick={() => reportAdvisorClick()}>
          <div className={classes.menuItem}>
            <ACIcon
              iconname="flag-outlined"
              style={{ fontSize: 15, marginBottom: "2px", marginRight: "5px" }}
            />
            <Typography variant="caption" className={classes.menuItemText}>
              Report this advisor
            </Typography>
          </div>
        </MenuItem>
      </React.Fragment>
    );
  };

  function renderContentMenu() {
    return <Default />;
  }

  return (
    <div>
      <button
        type="button"
        aria-controls="fade-menu"
        aria-haspopup="true"
        onClick={(event) => {
          handleClick(event);
          event.stopPropagation();
        }}
        className={classes.button}
      >
        <MoreVertIcon className={classes.elipsies} />
      </button>
      <Backdrop 
        className={classes.backDrop}
        open={open}
        onClick={(event) => {
          handleClose();
          event.stopPropagation();
        }}
      >
        <Menu
          id="fade-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <div>{renderContentMenu()}</div>
        </Menu>
      </Backdrop>
    </div>
  );
}

export default VerticalElipsiesMenu;
