import React from "react";
import {
  Paper,
  makeStyles,
  Typography,
  useMediaQuery,
  ThemeProvider,
} from "@material-ui/core";
import IconUnderlay from "../IconUnderlay";
import ACIcon from "../ACIcon";
import { default as ACTheme } from "../../themes";

const useStyles = makeStyles((theme) => ({
  container: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    minHeight: (isSM) => (isSM ? 75 : 113),
    minWidth: (isSM) => (isSM ? 152 : 228),
    paddingLeft: 20,
    paddingRight: 20,
    borderRadius: 5,
  },
  indicatorContainer: {
    display: "flex",
    flexDirection: "column",
    marginLeft: 30,
  },
  indicatorText: {
    fontWeight: "bold",
    marginBottom: 10,
  },
  labelText: {
    fontWeight: "bold",
    color: theme.palette.grey[500],
  },
}));

export default function DashboardCard(props) {
  return (
    <ThemeProvider theme={ACTheme}>
      <DashboardCardContent {...props} />
    </ThemeProvider>
  );
}

function DashboardCardContent({ indicator, label, icon }) {
  const isSM = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles(isSM);

  return (
    <Paper className={classes.container} elevation={0}>
      <IconUnderlay size={isSM ? 53 : 70}>
        <ACIcon
          iconname={icon}
          color="primary"
          style={{
            fontSize: isSM ? 32 : 55,
          }}
        />
      </IconUnderlay>
      <div className={classes.indicatorContainer}>
        <Typography
          variant={isSM ? "caption" : "h5"}
          className={classes.indicatorText}
        >
          {indicator}
        </Typography>
        <Typography
          variant={isSM ? "caption" : "h2"}
          className={classes.labelText}
        >
          {label}
        </Typography>
      </div>
    </Paper>
  );
}
