import React from 'react';
import { makeStyles, Typography, ThemeProvider } from '@material-ui/core';
import { default as ACTheme } from "../../themes";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: 'auto',
    width: '100%',
  },
  title: {
    color: theme.palette.primary.main,
    textAlign: 'center',
    margin: 'auto',
  },
  content: {
    color: theme.palette.info.main,
    textAlign: 'center',
    margin: 'auto',
  },
}));

export default function TitleAndContent(props) {
  return (
    <ThemeProvider theme={ACTheme}>
      <TitleAndContentContent {...props} />
    </ThemeProvider>
  );
}

function TitleAndContentContent(props) {
  const { titleText, contentText } = props;
  const classes = useStyles();

  return (
    <section className={classes.container}>
      <Typography className={classes.title} variant="subtitle1">
        {titleText}
      </Typography>
      <Typography className={classes.content} variant="subtitle2">
        {contentText}
      </Typography>
    </section>
  );
}
