import React from "react";
import { makeStyles, Typography, ThemeProvider } from "@material-ui/core";
import { CakeOutlined } from "@material-ui/icons";
import ACIcon from "../ACIcon";
import { default as ACTheme } from "../../themes";

const useStyles = makeStyles((theme) => ({
  tileContainer: {
    display: "flex",
    flexDirection: "column",
    border: "1px solid rgb(230,237,254)",
    backgroundColor: "white",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    position: "relative",
    width: 225,
    height: 225,
    flexShrink: 0,
  },
  textContainer: {
    position: "absolute",
    bottom: "9%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "fit-content",
  },
  titleText: {
    fontWeight: "bold",
    fontSize: "0.9rem",
    color: theme.palette.info.dark,
  },
  mainIconContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    position: "relative",
  },
  planCodeContainer: {
    marginTop: "35px",
    position: "relative",
  },
  planCodeBackdrop: {
    position: "absolute",
    width: 33,
    height: 55,
    top: 16,
    right: 12,
    backdropFilter: "grayscale(60%) opacity(90%)",
  },
  companyCheckoutContainer: {
    marginTop: "47px",
    marginLeft: "-10px",
  },
  companyCheckoutContainerDollar: {
    position: "absolute",
    width: "fit-content",
    height: "fit-content",
    top: 40,
    right: 60,
    fontSize: "2.5rem",
    fontWeight: 100,
    color: theme.palette.primary.main,
  },
  advisorCheckoutContainer: {
    marginTop: "47px",
    marginLeft: "-10px",
  },
  advisorCheckoutDollar: {
    position: "absolute",
    width: "fit-content",
    height: "fit-content",
    top: 40,
    right: 60,
    fontSize: "2.5rem",
    fontWeight: 100,
    color: theme.palette.primary.main,
  },
  masterCheckoutContainer: {
    marginTop: "47px",
    marginLeft: "-10px",
    position: "relative",
  },
  masterCheckoutDollar: {
    position: "absolute",
    width: "fit-content",
    height: "fit-content",
    top: "25%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    fontSize: "4.8rem",
    fontWeight: 100,
    color: theme.palette.primary.main,
  },
  advisorUpdates: {
    marginTop: "50px",
    marginLeft: "-10px",
  },
  advisorUpdatesFile: {
    position: "absolute",
    width: "fit-content",
    height: "fit-content",
    top: 45,
    right: 60,
    fontSize: "2.5rem",
    fontWeight: 100,
    color: "rgb(15,120,253)",
  },
  manageEmployeesContainer: {
    marginTop: "50px",
    marginLeft: "-10px",
  },
  planMappingContainer: {
    marginTop: "50px",
  },
  buttonStyle: {
    padding: 0,
    margin: 0,
    backgroundColor: "transparent",
    outline: "none",
    border: "none",
    cursor: "pointer",
    "&:disabled": {
      cursor: "default",
    },
  },
}));

export default function AdminConsoleTile(props) {
  return (
    <ThemeProvider theme={ACTheme}>
      <AdminConsoleTileContent {...props} />
    </ThemeProvider>
  );
}

function AdminConsoleTileContent(props) {
  const { title, type, onClick, disabled } = props;
  const classes = useStyles();

  function iconSelector() {
    if (type === "plancode") {
      return (
        <div className={classes.planCodeContainer}>
          <div className={classes.planCodeBackdrop} />
          <ACIcon
            iconname="updates-outlined"
            style={{
              fontSize: 85,
              marginLeft: "14px",
              color: "rgb(15,120,253)",
              display: "flex",
              alignItems: "center",
              alignContent: "center",
              height: "fit-content",
              width: "fit-content",
            }}
          />
        </div>
      );
    }
    if (type === "companyCheckout") {
      return (
        <div className={classes.companyCheckoutContainer}>
          <div className={classes.companyCheckoutContainerDollar}>
            {"\uFF04"}
          </div>
          <ACIcon
            iconname="company-outlined"
            style={{
              fontSize: 70,
              color: "#c4d5ff",
              display: "flex",
              alignItems: "center",
              alignContent: "center",
              justifyContent: "center",
              height: "fit-content",
              width: "fit-content",
            }}
          />
        </div>
      );
    }
    if (type === "advisorCheckout") {
      return (
        <div className={classes.advisorCheckoutContainer}>
          <div className={classes.advisorCheckoutDollar}>{"\uFF04"}</div>
          <ACIcon
            iconname="advisor-outlined"
            style={{
              fontSize: 70,
              color: "#c4d5ff",
              display: "flex",
              alignItems: "center",
              alignContent: "center",
              justifyContent: "center",
              height: "fit-content",
              width: "fit-content",
            }}
          />
        </div>
      );
    }
    if (type === "masterCheckout") {
      return (
        <div className={classes.masterCheckoutContainer}>
          <div className={classes.masterCheckoutDollar}>{"\uFF04"}</div>
        </div>
      );
    }
    if (type === "advisoryUpdates") {
      return (
        <div className={classes.advisorUpdates}>
          <div className={classes.advisorUpdatesFile}>
            <ACIcon
              iconname="docs-outlined"
              style={{
                fontSize: 35,
                color: "#c4d5ff",
                display: "flex",
                alignItems: "center",
                alignContent: "center",
                justifyContent: "center",
                height: "fit-content",
                width: "fit-content",
              }}
            />
          </div>
          <ACIcon
            iconname="cloud-logo-outlined"
            style={{
              fontSize: 90,
              color: "rgb(15,120,253)",
              display: "flex",
              alignItems: "center",
              alignContent: "center",
              justifyContent: "center",
              height: "fit-content",
              width: "fit-content",
            }}
          />
        </div>
      );
    }
    if (type === "manageEmployees") {
      return (
        <div className={classes.manageEmployeesContainer}>
          <ACIcon
            iconname="meeting-outlined"
            style={{
              fontSize: 90,
              color: "rgb(15,120,253)",
              display: "flex",
              alignItems: "center",
              alignContent: "center",
              justifyContent: "center",
              height: "fit-content",
              width: "fit-content",
            }}
          />
        </div>
      );
    }
    if (type === "planMapping") {
      return (
        <div className={classes.planMappingContainer}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              alignContent: "center",
              justifyContent: "center",
              height: "fit-content",
              width: "fit-content",
            }}
          >
            <CakeOutlined
              color="primary"
              style={{
                fontSize: 90,
              }}
            />
          </div>
        </div>
      );
    }
  }

  return (
    <button
      className={classes.buttonStyle}
      type="button"
      onClick={() => onClick()}
      disabled={disabled}
    >
      <div className={classes.tileContainer}>
        <div className={classes.mainIconContainer}>{iconSelector()}</div>
        <div className={classes.textContainer}>
          <Typography className={classes.titleText}>{title}</Typography>
        </div>
      </div>
    </button>
  );
}
