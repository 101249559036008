import React from "react";
import {
  makeStyles,
  Typography,
  Badge,
  withStyles,
  ThemeProvider,
} from "@material-ui/core";
import ACIcon from "../ACIcon";
import { default as ACTheme } from "../../themes";

const useStyles = makeStyles((theme) => ({
  mainConatiner: {
    display: "flex",
    flexDirection: "row",
    flexShrink: 0,
  },
  shrinkControlContainer: {
    flexShrink: 0,
  },
  button: {
    border: "none",
    display: "flex",
    outline: "none",
    backgroundColor: "transparent",
    flexShrink: 0,
    padding: 0,
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    width: "fit-content",
    "&:disabled": {
      cursor: "default",
    },
  },
  answersTextLarge: {
    marginTop: 3,
    color: theme.palette.info.dark,
  },
  answersTextMobile: {
    color: theme.palette.info.dark,
    fontSize: "0.7rem",
  },
  badge: {
    fontSize: 10,
  },
}));

const StyledBadge = withStyles(() => ({
  badge: {
    height: 14,
    minWidth: 14,
    padding: 0,
    borderRadius: "50%",
    right: 24,
    top: 7,
    fontSize: 8,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 2,
  },
}))(Badge);

const StyledBadgeXS = withStyles(() => ({
  badge: {
    height: 11,
    minWidth: 11,
    padding: 0,
    borderRadius: "50%",
    right: 19.5,
    top: 1.5,
    fontSize: 8,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 1,
  },
}))(Badge);

export default function AnswersComponent(props) {
  return (
    <ThemeProvider theme={ACTheme}>
      <AnswersComponentContent {...props} />
    </ThemeProvider>
  );
}

function AnswersComponentContent(props) {
  const classes = useStyles();
  const {
    numAnswers,
    onClick,
    filled,
    badge,
    disabled,
    badgeContent,
    iconSize,
  } = props;

  const unsetNumber =
    numAnswers === null || numAnswers === undefined || isNaN(numAnswers);

  function renderIcon() {
    if (filled) {
      return "answer";
    }
    return "answer-outlined";
  }

  function renderContent() {
    if (
      badgeContent === null ||
      badgeContent === undefined ||
      isNaN(badgeContent)
    ) {
      return <span>&#9679;</span>;
    }
    return badgeContent;
  }

  function renderAnswers() {
    if (unsetNumber && iconSize === "xs") {
      return (
        <Typography variant="caption" className={classes.answersTextMobile}>
          0 Answers
        </Typography>
      );
    }
    if (unsetNumber) {
      return (
        <Typography variant="body1" className={classes.answersTextLarge}>
          0 Answers
        </Typography>
      );
    }
    if (!unsetNumber && iconSize === "xs") {
      return (
        <Typography variant="caption" className={classes.answersTextMobile}>
          {`${numAnswers} Answers`}
        </Typography>
      );
    }
    if (!unsetNumber) {
      return (
        <Typography variant="body1" className={classes.answersTextLarge}>
          {`${numAnswers} Answers`}
        </Typography>
      );
    }
    return null;
  }

  function renderBadge() {
    if (badge || badgeContent) {
      return false;
    }
    return true;
  }

  function sizeSelector() {
    if (iconSize === "lg") {
      return 23;
    }
    if (iconSize === "xs") {
      return 18;
    }
    return 23;
  }

  function renderBadgeStyle() {
    if (iconSize === "lg") {
      return (
        <StyledBadge
          badgeContent={renderContent()}
          color="error"
          invisible={renderBadge()}
        >
          <ACIcon
            iconname={renderIcon()}
            style={{
              color: "#162F4B",
              fontSize: sizeSelector(),
              marginRight: "3px",
            }}
          />
        </StyledBadge>
      );
    }
    if (iconSize === "xs") {
      return (
        <StyledBadgeXS
          badgeContent={renderContent()}
          color="error"
          invisible={renderBadge()}
        >
          <ACIcon
            iconname={renderIcon()}
            style={{
              color: "#162F4B",
              fontSize: sizeSelector(),
              marginRight: "2px",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              marginTop: -3,
            }}
          />
        </StyledBadgeXS>
      );
    }
    return (
      <StyledBadge
        badgeContent={renderContent()}
        color="error"
        invisible={renderBadge()}
      >
        <ACIcon
          iconname={renderIcon()}
          style={{
            color: "#162F4B",
            fontSize: sizeSelector(),
            marginRight: "3px",
          }}
        />
      </StyledBadge>
    );
  }

  return (
    <div className={classes.mainConatiner}>
      <div className={classes.shrinkControlContainer}>
        <button
          className={classes.button}
          onClick={(event) => {
            onClick();
            event.stopPropagation();
          }}
          id="answersButton"
          type="button"
          disabled={disabled}
        >
          {renderBadgeStyle()}

          {renderAnswers()}
        </button>
      </div>
    </div>
  );
}
