import React from "react";
import {
  makeStyles,
  Typography,
  useMediaQuery,
  ThemeProvider,
} from "@material-ui/core";
import ACIcon from "../ACIcon";
import { default as ACTheme } from "../../themes";

const useStyles = makeStyles((theme) => ({
  mainConatiner: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  shrinkControlContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    flexWrap: "wrap",
  },
  mainArea: {
    flexShrink: 0,
    marginRight: 5,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "fit-content",
    paddingRight: 8,
    height: (isSM) => (isSM ? 15 : 16),
  },
  mainAreaDefaultSetting: {
    backgroundColor: "rgb(189,210,240)",
    borderRadius: "15px",
  },
  mainAreaAlternate: {
    borderRadius: "5px",
    backgroundColor: theme.palette.primary.main,
  },
  mainAreaSecondAlternate: {
    borderRadius: "5px",
    backgroundColor: "#24A0A3",
  },
  mainAreaMeeting: {
    borderRadius: "5px",
    backgroundColor: "#7F00FF",
  },
  text: {
    color: "#ffffff",
    fontSize: (isSM) => (isSM ? ".64rem" : ".74rem"),
    marginLeft: 3,
    fontWeight: 500,
  },
  circle: {
    height: (isSM) => (isSM ? 15 : 16),
    width: (isSM) => (isSM ? 15 : 16),
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  circleDefaultSetting: {
    borderRadius: "50%",
    backgroundColor: theme.palette.primary.main,
  },
  circleAlternate: {
    borderRadius: "30%",
    paddingLeft: "6px",
    backgroundColor: theme.palette.primary.main,
  },
  circleSecondAlternate: {
    borderRadius: "30%",
    paddingLeft: "6px",
    backgroundColor: "#24A0A3",
  },
  circleMeeting: {
    borderRadius: "30%",
    paddingLeft: "6px",
    backgroundColor: "#7F00FF",
  },
  dateText: {
    color: theme.palette.secondary.main,
    fontSize: (isSM) => (isSM ? ".64rem" : ".74rem"),
  },
}));

export default function ProfileHighlighterPill(props) {
  return (
    <ThemeProvider theme={ACTheme}>
      <ProfileHighlighterPillContent {...props} />
    </ThemeProvider>
  );
}

function ProfileHighlighterPillContent(props) {
  const isSM = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles(isSM);
  const { type, date, noLogo, alternateSettings, CustomIcon, isMeeting } =
    props;

  const setDate = new Date(date);
  const month = setDate.getMonth() + 1;
  const day = setDate.getDate();
  const year = setDate.getFullYear();
  const iconStyle = {
    color: "#ffffff",
    fontSize: 11,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: 1.5,
    paddingBottom: 1,
    width: "17px",
  };

  const reformatDate = () => {
    let formatMonth = "";
    let formatDay = "";

    if (String(day).length < 2) {
      formatDay = "0" + day;
    } else {
      formatDay = day;
    }

    if (String(month).length < 2) {
      formatMonth = "0" + month;
    } else {
      formatMonth = month;
    }

    return formatMonth + "/" + formatDay + "/" + year;
  };

  function renderDate() {
    if (date === null || date === undefined) {
      return null;
    }
    return (
      <Typography className={classes.dateText}>
        Since {reformatDate()}
      </Typography>
    );
  }

  function getMainClass() {
    if (isMeeting) {
      return classes.mainAreaMeeting;
    }
    if (alternateSettings) {
      return classes.mainAreaAlternate;
    }
    if (type === "Peer Advisory Board") {
      return classes.mainAreaSecondAlternate;
    }
    return classes.mainAreaDefaultSetting;
  }

  function getCircleClass() {
    if (isMeeting) {
      return classes.circleMeeting;
    }
    if (alternateSettings) {
      return classes.circleAlternate;
    }
    if (type === "Peer Advisory Board") {
      return classes.circleSecondAlternate;
    }
    return classes.circleDefaultSetting;
  }

  return (
    <div className={classes.mainConatiner}>
      <div className={classes.shrinkControlContainer}>
        <div
          className={`${classes.mainArea} ${getMainClass()}`}
          id="profileHighlightPill"
        >
          {!noLogo ? (
            <div className={`${classes.circle} ${getCircleClass()} `}>
              {!CustomIcon ? (
                <ACIcon iconname="cloud-logo" style={iconStyle} />
              ) : (
                React.cloneElement(CustomIcon, { style: iconStyle })
              )}
            </div>
          ) : null}
          <Typography className={classes.text}>{type}</Typography>
        </div>
        {renderDate()}
      </div>
    </div>
  );
}
