import React from "react";
import { makeStyles, ThemeProvider } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { default as ACTheme } from "../../themes";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 55,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  label: {
    color: theme.palette.primary.main,
  },
  icon: {
    color: theme.palette.primary.main,
  },
}));

export default function Dropdown(props) {
  return (
    <ThemeProvider theme={ACTheme}>
      <DropdownContent {...props} />
    </ThemeProvider>
  );
}

function DropdownContent(props) {
  const { itemList, label } = props;
  const classes = useStyles();
  const handleChange = (event) => {
    event.target.value.callback();
  };

  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel
          shrink={false}
          className={classes.label}
          id="sort-select-label"
        >
          {label}
        </InputLabel>
        <Select
          disableUnderline
          inputProps={{
            classes: {
              icon: classes.icon,
            },
          }}
          labelId="sort-select-label"
          id="sort-select"
          value={label}
          onChange={handleChange}
        >
          {itemList.map((item) => {
            return (
              <MenuItem key={item.title} value={item}>
                {item.title}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
}
