import React from "react";
import { makeStyles, Typography, ThemeProvider } from "@material-ui/core";
import ACIcon from "../ACIcon";
import { default as ACTheme } from "../../themes";

const useStyles = makeStyles((theme) => ({
  mainConatiner: {
    display: "flex",
    flexDirection: "row",
    flexShrink: 0,
  },
  shrinkControlContainer: {
    flexShrink: 0,
  },

  button: {
    border: "none",
    display: "flex",
    outline: "none",
    backgroundColor: "transparent",
    flexShrink: 0,
    padding: 0,
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    width: "fit-content",
    "&:disabled": {
      cursor: "default",
    },
  },
  likesText: {
    marginTop: 3,
    color: theme.palette.info.dark,
  },
  likesTextMobile: {
    color: theme.palette.info.dark,
    fontSize: "0.7rem",
  },
}));

export default function LikeComponent(props) {
  return (
    <ThemeProvider theme={ACTheme}>
      <LikeComponentContent {...props} />
    </ThemeProvider>
  );
}

function LikeComponentContent(props) {
  const classes = useStyles();
  const { numLikes, onClick, iconSize, filled, disabled, isFirst } = props;

  const unsetNumber =
    numLikes === null || numLikes === undefined || isNaN(numLikes);

  function singularPlural(number) {
    if (number === 1) {
      return "Like";
    }
    return "Likes";
  }

  function renderLikes() {
    if (unsetNumber && iconSize === "xs") {
      return (
        <Typography variant="caption" className={classes.likesTextMobile}>
          0 Likes
        </Typography>
      );
    }
    if (unsetNumber) {
      return (
        <Typography variant="body1" className={classes.likesText}>
          0 Likes
        </Typography>
      );
    }
    if (!unsetNumber && iconSize === "xs") {
      return (
        <Typography variant="caption" className={classes.likesTextMobile}>
          {`${numLikes} ${singularPlural(numLikes)}`}
        </Typography>
      );
    }
    if (!unsetNumber) {
      return (
        <Typography variant="body1" className={classes.likesText}>
          {`${numLikes} ${singularPlural(numLikes)}`}
        </Typography>
      );
    }
    return null;
  }

  function sizeSelector() {
    if (iconSize === "sm") {
      return 15;
    }
    if (iconSize === "lg") {
      return 22;
    }
    if (iconSize === "xs") {
      return 13;
    }
    return 15;
  }

  function renderIcon() {
    if (filled) {
      return "like";
    }
    return "like-outlined";
  }

  return (
    <div className={classes.mainConatiner}>
      <div className={classes.shrinkControlContainer}>
        <button
          className={
            isFirst ? `${classes.button} boardroomFeaturedLike` : classes.button
          }
          onClick={() => onClick()}
          id="likeButton"
          type="button"
          disabled={disabled}
        >
          <ACIcon
            iconname={renderIcon()}
            style={{
              color: "#162F4B",
              fontSize: sizeSelector(),
              marginRight: iconSize === "xs" ? "2px" : "3px",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              marginTop: iconSize === "xs" ? -5 : -3,
            }}
          />
          {renderLikes()}
        </button>
      </div>
    </div>
  );
}
