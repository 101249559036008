import React from "react";
import {
  makeStyles,
  Typography,
  useMediaQuery,
  ThemeProvider,
} from "@material-ui/core";
import PostHeaderAvatar from "../../PostHeaderAvatar";
import VerticalElipsiesMenu from "./RightHandVerticalElipsies";
import PostTypeIcon from "./PostTypeDecorator";
import TimeElapsed from "../../TimeElapsed";
import ACIcon from "../../ACIcon";
import { default as ACTheme } from "../../../themes";

const useStyles = makeStyles((theme) => ({
  postCardHeaderContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  rightGroup: {
    display: "flex",
    width: "fit-content",
    height: "fit-content",
    justifyContent: "center",
    alignItems: "center",
  },
  rightText: {
    display: "flex",
    textAlign: "end",
    justifyContent: "end",
    alignItems: "end",
    flexDirection: "column",
  },
  iconContainer: {
    marginRight: "5px",
  },
  pinnedFont: {
    marginRight: "5px",
    fontWeight: 450,
    fontSize: (isSM) => (isSM ? "10px" : "12px"),
    color: theme.palette.grey[700],
  },
  pinnedIcon: {
    color: theme.palette.grey[700],
    fontSize: 14,
  },
  pillContainer: {
    flexShrink: 0,
    display: "flex",
    flexDirection: "row",
    justifyContent: (isSM) => (isSM ? "flex-end" : "flex-start"),
    alignItems: "center",
    width: "fit-content",
    padding: (isSM) => (isSM ? "1px 5px 1px 5px" : "3px 12px 3px 12px"),
    color: "#273762",
    backgroundColor: "#E6EDFE",
    borderRadius: "15px",
    marginTop: 5,
  },
}));

export default function PostCardHeader(props) {
  return (
    <ThemeProvider theme={ACTheme}>
      <PostCardHeaderContent {...props} />
    </ThemeProvider>
  );
}

function PostCardHeaderContent(props) {
  const {
    noEllipsis,
    type,
    initialTime,
    companyName,
    dashboardFormat,
    editClick,
    deleteClick,
    isOpportunityView,
    companyAvatar,
    pinClick,
    isPinned,
  } = props;
  const isSM = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles(isSM);

  function typeGroupSelector() {
    if (noEllipsis) {
      return null;
    }
    return (
      <VerticalElipsiesMenu
        editClick={editClick}
        pinClick={pinClick}
        isPinned={isPinned}
        deleteClick={deleteClick}
      />
    );
  }

  function typeIconSelector() {
    if (type === "question") {
      return (
        <div className={classes.iconContainer}>
          <PostTypeIcon type="question" />
        </div>
      );
    }
    if (type === "update") {
      return (
        <div className={classes.iconContainer}>
          <PostTypeIcon type="update" />
        </div>
      );
    }
    return null;
  }

  function timeElapsedResults() {
    return isOpportunityView ? (
      ""
    ) : (
      <TimeElapsed
        initialTime={initialTime}
        dashboardFormat={dashboardFormat ? 1 : 0}
        size={isSM ? "sm" : "lg"}
      />
    );
  }

  function pinnedText() {
    if (isPinned) {
      return (
        <div className={classes.pillContainer}>
          <Typography className={classes.pinnedFont}>
            {isSM ? null : (
              <ACIcon
                classes={{
                  root: classes.pinnedIcon,
                }}
                iconname="push-pin"
              />
            )}
            Pinned
          </Typography>
        </div>
      );
    }
    return null;
  }

  return (
    <div className={classes.postCardHeaderContainer}>
      <PostHeaderAvatar
        titleName={companyName}
        subtitle={timeElapsedResults()}
        fontSize={isSM ? "sm" : "lg"}
        image={companyAvatar}
      />
      <div className={classes.rightGroup}>
        <div className={classes.rightText}>
          {typeIconSelector()}
          {pinnedText()}
        </div>
        {typeGroupSelector()}
      </div>
    </div>
  );
}
