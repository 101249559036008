import React, { useState } from "react";
import {
  makeStyles,
  useMediaQuery,
  Paper,
  ThemeProvider,
} from "@material-ui/core";
import PostCardBottom from "../PostCardBottom";
import AnswerHeader from "./AnswerHeader";
import AnswerBody from "./AnswerBody";
import { default as ACTheme } from "../../themes";

const useStyles = makeStyles((theme) => ({
  card: {
    width: "100%",
    borderRadius: 0,
    display: "flex",
    flexDirection: "column",
    height: "fit-content",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    backgroundColor: "transparent",
  },
  separator: {
    marginBottom: "9px",
    width: "100%",
  },
  postCardBottom: {
    paddingLeft: (isSM) => (isSM ? 45 : 52),
  },
}));

export default function AnswerCard(props) {
  return (
    <ThemeProvider theme={ACTheme}>
      <AnswerCardContent {...props} />
    </ThemeProvider>
  );
}

const AnswerCardContent = ({
  // body
  body,
  // likes
  likes,
  likesClick,
  likesFilled,
  likesDisabled,
  // comments
  comments,
  commentsClick,
  commentsFilled,
  commentsBadge,
  commentsBadgeContent,
  commentsDisabled,
  // breakthroughs
  breakthroughs,
  breakthroughsClick,
  breakthroughsFilled,
  breakthroughsGold,
  breakthroughsBold,
  breakthroughsDisabled,
  // date and preview format
  createdAt,
  // avatars & others
  avatarClick,
  answerName,
  subtitle,
  answerAvatar,
  isOwner,
  answerBodyClick,
  answerBodyDisabled,
  isStar,
  isStarFilled,
  starClicked,
  updateValue,
  ProfileHighlighterType,
}) => {
  const isSM = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles(isSM);
  const [isEditing, setIsEditing] = useState(false);

  function toggleEditing() {
    setIsEditing(!isEditing);
  }

  return (
    <Paper elevation={0} className={classes.card}>
      <div className={classes.separator} style={{ width: "100%" }}>
        <AnswerHeader
          headerName={answerName}
          subtitle={subtitle}
          answerAvatar={answerAvatar}
          isOwner={isOwner}
          onEditClick={toggleEditing}
          isStar={isStar}
          isStarFilled={isStarFilled}
          starClicked={starClicked}
          ProfileHighlighterType={ProfileHighlighterType}
          avatarClick={avatarClick}
        />
      </div>
      <div className={classes.separator}>
        <AnswerBody
          body={body}
          initialTime={createdAt}
          bodyDisabled={answerBodyDisabled}
          bodyClick={answerBodyClick}
          editing={isEditing}
          setEditFalse={toggleEditing}
          updateValue={updateValue}
        />
      </div>
      {isEditing ? null : (
        <div className={classes.postCardBottom}>
          <PostCardBottom
            size={isSM ? "xs" : "sm"}
            // likes
            likes={likes}
            likesClick={likesClick}
            likesFilled={likesFilled}
            likesDisabled={likesDisabled}
            // comments
            comments={comments}
            commentsClick={commentsClick}
            commentsFilled={commentsFilled}
            commentsBadge={commentsBadge}
            commentsBadgeContent={commentsBadgeContent}
            commentsDisabled={commentsDisabled}
            // breakthroughs
            breakthroughs={breakthroughs}
            breakthroughsClick={breakthroughsClick}
            breakthroughsFilled={breakthroughsFilled}
            breakthroughsGold={breakthroughsGold}
            breakthroughsBold={breakthroughsBold}
            breakthroughsDisabled={breakthroughsDisabled}
          />
        </div>
      )}
    </Paper>
  );
};
