import React from "react";
import { makeStyles, useMediaQuery, ThemeProvider } from "@material-ui/core";
import LikeButton from "../LikeIconComponent";
import AnswersButton from "../AnswerIconComponent";
import BreakthroughsButton from "../BreakthroughsIconComponent";
import CommentsButton from "../CommentsButton";
import AvatarsInvolved from "../AvatarsInvolved";
import { default as ACTheme } from "../../themes";

const useStyles = makeStyles({
  groupContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  iconContainer: {
    display: "flex",
    height: "fit-content",
  },

  likesContainer: {
    order: (props) => props.likesOrder,
  },
  answersContainer: {
    order: (props) => props.answersOrder,
  },
  commentsContainer: {
    order: (props) => props.commentsOrder,
  },
  avatarsContainer: {
    display: "flex",
    height: "100%",
  },
  iconSeparatorBreakthroughs: {
    marginTop: 0,
    order: (props) => props.breakthoughsOrder,
  },
  iconSeparatorBreakthroughsXs: {
    marginTop: -2,
    order: (props) => props.breakthoughsOrder,
  },
  contributorsButton: {
    border: "none",
    outline: "none",
    cursor: "pointer",
    "&:disabled": {
      cursor: "default",
    },
    backgroundColor: "transparent",
  },
});

export default function PostCardBottom(props) {
  return (
    <ThemeProvider theme={ACTheme}>
      <PostCardBottomContent {...props} />
    </ThemeProvider>
  );
}

const PostCardBottomContent = (props) => {
  const {
    size,
    likes,
    answers,
    comments,
    breakthroughs,
    likesFilled,
    likesClick,
    likesDisabled,
    commentsFilled,
    commentsClick,
    commentsBadge,
    commentsBadgeContent,
    commentsDisabled,
    answersClick,
    answersFilled,
    answersBadge,
    answersBadgeContent,
    answersDisabled,
    breakthroughsClick,
    breakthroughsFilled,
    breakthroughsGold,
    breakthroughsBold,
    breakthroughsSize,
    breakthroughsDisabled,
    avatars,
    iconSeparation,
    contributorsDisabled,
    contributorsClick,
    welcomePostStaticClasses,
    isFirst,
  } = props;
  const classes = useStyles(props);
  const isSM = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  function renderBreakthroughsSize() {
    if (breakthroughsSize === "md") {
      return "md";
    }
    if (size === "sm" || breakthroughsSize === "sm") {
      return "sm";
    }
    if (size === "lg" || breakthroughsSize === "lg") {
      return "lg";
    }
    if (size === "xs" || breakthroughsSize === "xs") {
      return "xs";
    }

    return null;
  }

  function renderContributorsAvatar() {
    if (avatars === null || avatars === undefined) {
      return null;
    }
    if (avatars.length === 0) {
      return null;
    }
    return (
      <button
        type="button"
        className={classes.contributorsButton}
        onClick={() => contributorsClick()}
        disabled={contributorsDisabled}
      >
        <AvatarsInvolved avatars={avatars} />
      </button>
    );
  }

  function breakThroughsPositionSelector() {
    if (size === "xs") {
      return classes.iconSeparatorBreakthroughsXs;
    }
    if (size === "large") {
      return classes.iconSeparatorBreakthroughs;
    }
    return classes.iconSeparatorBreakthroughs;
  }

  function likesIconSeparation() {
    if (isSM) {
      return "8px";
    }
    if (iconSeparation) {
      return iconSeparation;
    }
    return "13px";
  }

  function iconSeparationSelection() {
    if (isSM) {
      return "8px";
    }
    if (iconSeparation) {
      return iconSeparation;
    }
    return 10;
  }

  return (
    <div className={classes.groupContainer}>
      <div className={classes.iconContainer}>
        {likes || likes === 0 ? (
          <div
            className={`${classes.likesContainer}${
              welcomePostStaticClasses ? " likeContainer" : ""
            }`}
            style={{ marginRight: likesIconSeparation() }}
          >
            <LikeButton
              iconSize={size}
              numLikes={likes}
              onClick={likesClick}
              filled={likesFilled}
              disabled={likesDisabled}
              isFirst={isFirst}
            />
          </div>
        ) : null}
        {answers || answers === 0 ? (
          <div
            className={classes.answersContainer}
            style={{ marginRight: iconSeparationSelection() }}
          >
            <AnswersButton
              iconSize={size}
              numAnswers={answers}
              badge={answersBadge}
              filled={answersFilled}
              onClick={answersClick}
              badgeContent={answersBadgeContent}
              disabled={answersDisabled}
            />
          </div>
        ) : null}
        {comments || comments === 0 ? (
          <div
            className={classes.commentsContainer}
            style={{ marginRight: iconSeparationSelection() }}
          >
            <CommentsButton
              iconSize={size}
              numComments={comments}
              filled={commentsFilled}
              onClick={commentsClick}
              badge={commentsBadge}
              badgeContent={commentsBadgeContent}
              disabled={commentsDisabled}
            />
          </div>
        ) : null}
        {breakthroughs || breakthroughs === 0 ? (
          <div
            className={breakThroughsPositionSelector()}
            style={{ marginRight: iconSeparationSelection() }}
          >
            <BreakthroughsButton
              onClick={breakthroughsClick}
              filled={breakthroughsFilled}
              goldColor={breakthroughsGold}
              bold={breakthroughsBold}
              iconSize={renderBreakthroughsSize()}
              numBreakthroughs={breakthroughs}
              disabled={breakthroughsDisabled}
            />
          </div>
        ) : null}
      </div>
      <div className={classes.avatarsContainer}>
        {renderContributorsAvatar()}
      </div>
    </div>
  );
};
