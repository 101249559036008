import React, { useEffect, useState } from "react";
import { makeStyles, Tooltip, ThemeProvider } from "@material-ui/core";
import PasswordField from "../PasswordField";
import errorCodes from "./errorCodes.json";
import { default as ACTheme } from "../../themes";

const useStyles = makeStyles((theme) => ({
  validationMessage: {
    color: theme.palette.error.main,
  },
  inputsContainer: {
    display: "flex",
    flexDirection: "column",
    height: 180,
    justifyContent: "space-between",
  },
  arrow: {
    "&:before": {
      border: "2px solid #5A5A5A",
    },
    color: theme.palette.common.white,
  },
  tooltip: {
    backgroundColor: "#fafafa",
    border: "2px solid #5A5A5A",
    color: "#4A4A4A",
  },
}));

export default function Password(props) {
  return (
    <ThemeProvider theme={ACTheme}>
      <PasswordContent {...props} />
    </ThemeProvider>
  );
}

function PasswordContent(props) {
  const {
    confirmPasswordLabel,
    onError,
    onKeyUpData,
    onMatch,
    passwordLabel,
    validationMessage,
    width,
    passwordTooltip,
    confirmPasswordTooltip,
  } = props;
  const classes = useStyles();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  function handleOnKeyUp(event) {
    if (onKeyUpData) {
      onKeyUpData({
        id: event.target.id,
        content: event.target.value,
        allCharactersCount: event.target.value.length,
        withoutLineBreaksCount: event.target.value.replace(
          /(?:\r\n|\r|\n)/g,
          ""
        ).length,
      });
    }
  }

  function handlePasswordChange(event) {
    setPassword(event.target.value);
  }

  function handleConfirmPasswordChange(event) {
    setConfirmPassword(event.target.value);
  }

  function renderValidationMessage() {
    if (!validationMessage) return null;

    return (
      <span className={classes.validationMessage}>{validationMessage}</span>
    );
  }

  useEffect(() => {
    if (password.length && confirmPassword.length) {
      if (password === confirmPassword && onMatch) {
        onMatch(password);
      } else if (onError) {
        onError(errorCodes.PASSWORDS_NOT_MATCHING);
      }
    }
  }, [password, confirmPassword]);

  return (
    <div className={classes.inputsContainer}>
      <Tooltip
        arrow
        disableHoverListener
        placement="bottom-start"
        classes={{
          arrow: classes.arrow,
          tooltip: classes.tooltip,
        }}
        title={<React.Fragment>{passwordTooltip}</React.Fragment>}
      >
        <PasswordField
          id="password"
          onChange={handlePasswordChange}
          onKeyUp={handleOnKeyUp}
          value={password}
          label={passwordLabel}
          width={width}
          autoFocus={true}
        />
      </Tooltip>

      <Tooltip
        arrow
        disableHoverListener
        placement="bottom-start"
        classes={{
          arrow: classes.arrow,
          tooltip: classes.tooltip,
        }}
        title={<React.Fragment>{confirmPasswordTooltip}</React.Fragment>}
      >
        <PasswordField
          id="confirmPassword"
          onChange={handleConfirmPasswordChange}
          onKeyUp={handleOnKeyUp}
          value={confirmPassword}
          label={confirmPasswordLabel}
          width={width}
          autoFocus={false}
        />
      </Tooltip>

      {renderValidationMessage()}
    </div>
  );
}
