import React from 'react';
import { ThemeProvider } from '@material-ui/core';
import DefaultTheme from '../../themes';

function ComponentsProvider(props) {
  return (
    <ThemeProvider theme={props.theme || DefaultTheme}>
      {props.children}
    </ThemeProvider>
  );
}

export default ComponentsProvider;
