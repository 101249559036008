import React from "react";
import {
  makeStyles,
  Typography,
  Paper,
  useMediaQuery,
  ThemeProvider,
} from "@material-ui/core";
import { default as ACTheme } from "../../themes";

const useStyles = makeStyles((theme) => ({
  metricCard: {
    display: (isXS) => (isXS ? "inline-flex" : "flex"),
    width: (isXS) => (isXS ? "48%" : "275px"),
    height: (isXS) => (isXS ? "90px" : "120px"),
    boxShadow: "none",
    border: "solid 1px",
    borderColor: theme.palette.info.light,
  },
  image: {
    margin: "15px 5px",
    borderRadius: "50%",
  },
  metricText: {
    width: "160px",
    margin: "auto 10px auto 5px",
  },
  metricValue: {
    color: theme.palette.info.main,
  },
  biggerMetricValue: {
    color: theme.palette.info.main,
    lineHeight: 1.2,
    fontSize: (isXS) => (isXS ? "1.875rem" : "3.25rem"),
  },
  title: {
    color: theme.palette.info.light,
    marginBottom: "4px",
  },
  content: {
    color: theme.palette.info.light,
  },
}));

export default function CompanyMetric(props) {
  return (
    <ThemeProvider theme={ACTheme}>
      <CompanyMetricContent {...props} />
    </ThemeProvider>
  );
}

function CompanyMetricContent(props) {
  const {
    image,
    alt,
    metricValue,
    titleText,
    content,
    contentText,
    emptyState,
  } = props;
  const isXS = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const classes = useStyles(isXS);

  return (
    <Paper className={classes.metricCard}>
      <img
        src={image}
        alt={alt}
        width={isXS ? "60" : "90"}
        className={classes.image}
      />
      <div className={classes.metricText}>
        <Typography
          className={
            emptyState ? classes.metricValue : classes.biggerMetricValue
          }
          variant={emptyState ? "h5" : "h6"}
        >
          {metricValue}
        </Typography>
        <Typography className={classes.title} variant="h3">
          {titleText}
        </Typography>
        {emptyState ? content : null}
        {typeof content === "undefined" && emptyState ? (
          <Typography className={classes.content} variant="caption">
            {contentText}
          </Typography>
        ) : null}
      </div>
    </Paper>
  );
}
