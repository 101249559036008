import React from "react";
import {
  makeStyles,
  useMediaQuery,
  Typography,
  ThemeProvider,
} from "@material-ui/core";
import { default as ACTheme } from "../../themes";

const useStyles = makeStyles({
  avatarButtonSmall: {
    borderRadius: "50%",
    position: "relative",
    width: (isSM) => (isSM ? "34px" : "38px"),
    height: (isSM) => (isSM ? "34px" : "38px"),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "none",
    cursor: "pointer",
    "&:disabled": {
      cursor: "default",
    },
  },
  avatarButtonLarge: {
    borderRadius: "50%",
    position: "relative",
    width: (isSM) => (isSM ? "42px" : "56px"),
    height: (isSM) => (isSM ? "42px" : "56px"),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "none",
    cursor: "pointer",
    "&:disabled": {
      cursor: "default",
    },
  },
  textContainer: {
    position: "absolute",
    zIndex: 99,
  },
  textSmall: {
    textTransform: "uppercase",
    fontWeight: 600,
    color: "white",
    padding: 0,
    margin: 0,
    fontSize: (isSM) => (isSM ? "0.9rem" : "1.1rem"),
  },
  textLarge: {
    textTransform: "uppercase",
    fontWeight: 600,
    color: "white",
    padding: 0,
    margin: 0,
    fontSize: (isSM) => (isSM ? "1.2rem" : "1.6rem"),
  },
});

export default function LetterAvatar(props) {
  return (
    <ThemeProvider theme={ACTheme}>
      <LetterAvatarContent {...props} />
    </ThemeProvider>
  );
}

function LetterAvatarContent(props) {
  const { onClick, firstName, lastName, size, disabled, color } = props;
  const isSM = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles(isSM);

  const firstNameCapitalLetter = () => {
    if (firstName) {
      return firstName.charAt(0);
    }
    return null;
  };

  const lastNameCapitalLetter = () => {
    if (lastName) {
      return lastName.charAt(0);
    }
    return null;
  };

  function sizeSelector() {
    if (size === "small") {
      return classes.avatarButtonSmall;
    }
    if (size === "large") {
      return classes.avatarButtonLarge;
    }
    return classes.avatarButtonSmall;
  }

  function fontSelector() {
    if (size === "small") {
      return classes.textSmall;
    }
    if (size === "large") {
      return classes.textLarge;
    }
    return classes.textSmall;
  }

  return (
    <button
      type="button"
      onClick={onClick}
      className={sizeSelector()}
      disabled={disabled}
      style={{ backgroundColor: color ? color : "grey" }}
    >
      <div className={classes.textContainer}>
        <Typography className={fontSelector()}>
          {firstNameCapitalLetter()}
          {lastNameCapitalLetter()}
        </Typography>
      </div>
    </button>
  );
}
