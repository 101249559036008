import React, { useCallback } from "react";
import Icon from "@material-ui/core/Icon";
import { makeStyles, ThemeProvider } from "@material-ui/core";
import "../../font-icons/icons.css";
import { default as ACTheme } from "../../themes";

const useStyles = makeStyles(() => ({
  iconOverflow: {
    overflow: "visible",
  },
}));

export default function ACIcon(props) {
  return (
    <ThemeProvider theme={ACTheme}>
      <ACIconContent {...props} />
    </ThemeProvider>
  );
}

function ACIconContent(props) {
  const { iconname, onMount, id } = props;
  const classes = useStyles();

  const handleReference = useCallback((ref) => {
    if (onMount) {
      const data = { ref };

      if (id) {
        data.id = id;
      }

      onMount(data);
    }
  }, []);

  return (
    <Icon
      ref={handleReference}
      className={`ac-icon-${iconname} ${classes.iconOverflow}`}
      {...props}
    />
  );
}
