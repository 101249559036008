import React, { useState } from "react";
import {
  makeStyles,
  Typography,
  useMediaQuery,
  TextField,
} from "@material-ui/core";
import TimeElapsed from "../../TimeElapsed";
import Button from "../../Button";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    width: "100%",
    height: "fit-content",
    justifyContent: "flex-end",
    alignItems: "flex-start",
  },
  bodyIndividual: {
    width: "100%",
    flexGrow: 1,
    flexShrink: 1,
    height: "fit-content",
    padding: "9px 11px",
    color: theme.palette.info.dark,
    backgroundColor: "#F0F2F7",
    borderRadius: "3px",
  },
  timeContainer: {
    width: (isSM) => (isSM ? 45 : 52),
    paddingLeft: (isSM) => (isSM ? 9 : 13),
    height: "100%",
  },
  buttonBody: {
    borderRadius: "3px",
    border: "none",
    outline: "none",
    cursor: "pointer",
    "&:disabled": {
      cursor: "default",
    },
    backgroundColor: "transparent",
    width: "100%",
    margin: 0,
    padding: 0,
  },
  input: {
    minHeight: 20,
    padding: 7,
  },
  warningText: {
    color: theme.palette.error.main,
  },
}));

function AnswerBody(props) {
  const {
    body,
    initialTime,
    bodyDisabled,
    bodyClick,
    editing,
    updateValue,
    setEditFalse,
  } = props;
  const isSM = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles(isSM);
  const [inputValue, setInputValue] = useState(body);
  const IS_INPUT_VALID = inputValue.trim().length > 29;

  function handleButtonClick() {
    setEditFalse();
    updateValue(inputValue);
  }

  function formatBody(text) {
    const textArray = text.split(" ");
    const elementsArray = [];
    let wordBucket = [];
    textArray.forEach((word, index) => {
      if (word.includes(`\n`)) {
        elementsArray.push(
          <span key={`${index}s1`}>{wordBucket.join(" ")}</span>
        );
        elementsArray.push(<span key={`${index}s2`}> </span>);
        let separatedWord = word.split(`\n`);
        separatedWord.forEach((piece, pieceIndex) => {
          if (piece == "") {
            elementsArray.push(<br key={`${index}k${pieceIndex}`} />);
          } else {
            elementsArray.push(
              <span key={`${index}sp0${pieceIndex}`}>{piece}</span>
            );
            elementsArray.push(<span key={`${index}sp1${pieceIndex}`}> </span>);
          }
        });
        wordBucket = [];
      } else {
        wordBucket.push(word);
      }
    });

    elementsArray.push(<span key="finalItem">{wordBucket.join(" ")}</span>);
    return (
      <React.Fragment>{elementsArray.map((element) => element)}</React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <div className={classes.mainContainer}>
        <div className={classes.timeContainer}>
          <TimeElapsed initialTime={initialTime} size="sm" />
        </div>
        <button
          type="button"
          className={classes.buttonBody}
          onClick={() => bodyClick()}
          disabled={bodyDisabled || editing}
        >
          {editing ? (
            <div style={{ dispaly: "flex", flexDirection: "column" }}>
              <TextField
                fullWidth
                multiline
                onChange={(evt) => setInputValue(evt.target.value)}
                value={inputValue}
                placeholder="Add a comment..."
                InputProps={{
                  classes: {
                    root: classes.input,
                  },
                }}
                variant="outlined"
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "5px",
                }}
              >
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={handleButtonClick}
                  style={{ borderRadius: 5 }}
                  size={isSM ? "small" : "medium"}
                  disabled={!IS_INPUT_VALID}
                >
                  <Typography variant="body2">Update</Typography>
                </Button>
              </div>
              <Typography className={classes.warningText} variant="caption">
                {inputValue === undefined ||
                inputValue === null ||
                inputValue.length >= 30
                  ? null
                  : " A minimum of 30 characters are required."}
              </Typography>
            </div>
          ) : (
            <Typography
              id="bodyParagraph"
              className={classes.bodyIndividual}
              align="left"
            >
              {formatBody(inputValue, bodyClick)}
            </Typography>
          )}
        </button>
      </div>
    </React.Fragment>
  );
}

export default AnswerBody;
