import React from "react";
import { makeStyles, Typography, ThemeProvider } from "@material-ui/core";
import { default as ACTheme } from "../../themes";

const useStyles = makeStyles((theme) => ({
  metricContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: 200,
    height: 90,
  },
  image: {
    borderRadius: "50%",
  },
  metricText: {
    display: "flex",
    flexDirection: "column",
    marginLeft: 10,
  },
  title: {
    color: theme.palette.info.light,
  },
  metricValue: {
    color: theme.palette.info.main,
    fontWeight: "bold",
  },
}));

export default function AdvisorMetric(props) {
  return (
    <ThemeProvider theme={ACTheme}>
      <AdvisorMetricContent {...props} />
    </ThemeProvider>
  );
}

function AdvisorMetricContent(props) {
  const { image, alt, metricValue, titleText } = props;
  const classes = useStyles();

  return (
    <div className={classes.metricContainer}>
      <img className={classes.image} src={image} alt={alt} width={90} />
      <div className={classes.metricText}>
        <Typography className={classes.metricValue} variant="subtitle1">
          {metricValue}
        </Typography>
        <Typography variant="caption" className={classes.title}>
          {titleText}
        </Typography>
      </div>
    </div>
  );
}
